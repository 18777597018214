<template>
  <div style="width: 100%; height: 100%">
    <c-box v-if="invoiceData" p="2">
      <c-flex justify="space-between" mb="3">
        <c-heading size="lg" as="h3" color="rgba(0,48,143)">
          Earnings
        </c-heading>
        <c-box w="120px" size="sm">
          <c-select
            backgroundColor="tomato"
            borderColor="tomato"
            rounded="md"
            h="30px"
            fontSize="14px"
            color="white"
            v-model="period"
            :value="period"
            @change="loadChart"
          >
            <option value="yearly">Yearly</option>
            <option value="half">6 months</option>
            <option value="quarter">3 months</option>
            <option value="monthly">1 month</option>
            <option value="weekly">weekly</option>
          </c-select>
        </c-box>
      </c-flex>
      <c-flex mb="4">
        <c-box mr="5">
          <c-text fontWeight="600" color="primary" fontSize="13px">
            Total Earnings
          </c-text>
          <c-heading color="rgba(0,48,143)" size="xl" as="h4">
            {{ totalCommissionAmount }}
          </c-heading>
        </c-box>
        <c-box>
          <c-text color="primary" fontSize="13px" fontWeight="600">
            Earnings This Month
          </c-text>
          <c-heading color="rgba(0,48,143)" size="xl" as="h4">
            {{ currentMonthCommissionAmount }}
          </c-heading>
        </c-box>
      </c-flex>
      <c-flex
        flex="1"
        v-if="totalCommissionAmount === 0"
        flexDirection="column"
        justify="center"
        align="center"
        w="100%"
        h="100%"
      >
        <img
          height="200px"
          width="200px"
          src="@/assets/img/no_earnings.svg#no-earnings"
          alt="empty state"
        />
        <c-text fontSize="15px" fontWeight="700" mt="5px"
          >You have no earnings yet</c-text
        >
      </c-flex>
      <div v-else>
        <line-chart
          ref="chart_line_1"
          :chart-data="chartDetails.chartData"
          :gradient-stops="chartDetails.gradientStops"
          :extra-options="chartDetails.extraOptions"
        />
      </div>
    </c-box>
    <div
      class="flex justify-center items-center"
      style="height: 100%; width: 100%"
      v-else
    >
      <c-spinner thickness="4px" speed="0.65s" color="tomato" size="xl" />
    </div>
  </div>
</template>

<script>
import LineChart from '../../../components/charts/LineChart'
import * as chartConfigs from '../../../components/charts/config';
import config from '/config'
import moment from "moment";
import {getAppliedCouponForAffiliate} from "@/services/company";
import {mapGetters} from "vuex";
export default {
  name: 'EarningsSummaryCard',
  components: {
    LineChart
  },
  computed: {
    ...mapGetters({
      company: "company/getActiveCompany"
    }),

    // Total commission amount across all invoices
    totalCommissionAmount() {
      return this.invoiceData
          ? this.invoiceData.reduce((sum, item) => sum + (item.commissionAmount || 0), 0)
          : 0;
    },

    // Commission amount for the current month
    currentMonthCommissionAmount() {
      const currentMonth = moment().month();
      const currentYear = moment().year();

      return this.invoiceData
          ? this.invoiceData
              .filter(item => {
                const itemDate = moment(item.dateUsed);
                return itemDate.month() === currentMonth && itemDate.year() === currentYear;
              })
              .reduce((sum, item) => sum + (item.commissionAmount || 0), 0)
          : 0;
    }
  },
  data() {
    return {
      invoiceData: null,
      period: 'yearly',
      chartDetails: {
        activeIndex: 0,
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Earnings Summary',
              data: this.invoiceData,
              borderColor: '#FF6384',
              borderWidth: 2,
              pointBackgroundColor: '#FF6384',
              fill: true, // This ensures the area under the line is filled
              gradientColors: ['rgba(255,99,132,0.2)', 'rgba(255,99,132,0.0)', 'rgba(255,99,132,0)'],
              gradientStops: [1, 0.4, 0],
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      total:0,
    }
  },

  methods: {
    getFilteredData(period) {
      const now = moment();
      let startDate;

      switch (period) {
        case 'yearly':
          startDate = now.clone().subtract(12, 'months');
          break;
        case 'half':
          startDate = now.clone().subtract(6, 'months');
          break;
        case 'quarter':
          startDate = now.clone().subtract(3, 'months');
          break;
        case 'monthly':
          startDate = now.clone().subtract(5, 'weeks');
          break;
        case 'weekly':
          startDate = now.clone().subtract(7, 'days');
          break;
        default:
          startDate = now.clone().subtract(12, 'months');
      }

      return this.invoiceData.filter(item => moment(item.dateUsed).isBetween(startDate, now));
    },
    loadChart() {
      const now = moment();
      let labels = [];
      let data = [];

        if (this.period === 'yearly') {
          console.log('Hexing ==>')
          // Last 12 months
          labels = Array.from({ length: 12 }, (_, i) => now.clone().subtract(i, 'months').format('MMM YYYY')).reverse();
          data = this.generateEarningsData(12, 'months');
        } else if (this.period === 'half') {
          // Last 6 months
          labels = Array.from({ length: 6 }, (_, i) => now.clone().subtract(i, 'months').format('MMM YYYY')).reverse();
          data = this.generateEarningsData(6, 'months');
        } else if (this.period === 'quarter') {
          // Last 3 months (Quarter)
          labels = Array.from({ length: 3 }, (_, i) => now.clone().subtract(i, 'months').format('MMM YYYY')).reverse();
          data = this.generateEarningsData(3, 'months');
        } else if (this.period === 'monthly') {
          // Last 5 weeks
          labels = Array.from({ length: 5 }, (_, i) => `Week ${5 - i}`);
          data = this.generateWeeklyEarningsData(5, 'weeks');
        } else if (this.period === 'weekly') {
          // Last 7 days
          labels = Array.from({ length: 7 }, (_, i) => now.clone().subtract(i, 'days').format('DD MMM')).reverse();
          data = this.generateEarningsData(7, 'days');
        }

        let chartData = {
          labels,
          datasets: [
              {
                label: 'Affiliate Earnings',
                data,
                lineTension: .2,
                borderColor: config.colors.primary,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.primary,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.primary,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 15,
                pointRadius: 4
            }
          ],
        }
        this.$refs.chart_line_1.updateGradients(chartData);
        this.chartDetails.chartData = chartData;


    },
    generateEarningsData(periodLength, periodType) {
      const now = moment();
      const data = Array(periodLength).fill(0); // Initialize array with 0s

      console.log(this.invoiceData, 'Gemini******')

      this.invoiceData?.forEach(item => {
        const itemDate = moment(item.dateUsed);
        const diff = now.diff(itemDate, periodType);

        if (diff >= 0 && diff < periodLength) {
          // Sum commissionAmount if within the current period
          data[periodLength - diff - 1] += item.commissionAmount || 0;
        }
      });

      return data;
    },
    weekOfMonth (input = moment()) {
      // const firstDayOfMonth = input.clone().startOf('month');
      // const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
      // const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
      return Math.ceil((input.date()) / 7);
    },

    generateWeeklyEarningsData(weeks) {
      const now = moment();
      const data = Array(weeks).fill(0);

      this.invoiceData?.forEach(item => {
        const itemDate = moment(item.dateUsed);
        const weekDiff = Math.floor(now.diff(itemDate, 'days') / 7);

        if (weekDiff >= 0 && weekDiff < weeks) {
          data[weeks - weekDiff - 1] += item.commissionAmount || 0;
        }
      });

      return data;
    },

    getInvoiceData(){
      try{
        this.getAppliedCouponsData()
            .then((response) => {
              if(response.success){
                this.invoiceData = response.data
                console.log('Datax here', this.invoiceData)
                this.$nextTick(() => this.loadChart())
              }
              else{
                console.log('Something went wrong')
              }
            })
      }catch (e){
        console.log("Something went wrong. Please try again.")
      }

    },
    async getAppliedCouponsData(){
      try {
        const response = await getAppliedCouponForAffiliate(this.company.id, 0, 0)
        return { success: true, data: response.data.coupon_company }
      }catch (e) {
        return { success: false }
      }
    },


  },

  mounted() {
    this.getInvoiceData();
  },

  watch:{
    'period': function(newP, old) {
      if(newP !== old){
        this.loadChart()
      }
    },
  },
}
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%; /* Define chart size */
}
</style>
