<template>
  <c-box py="8">
    <c-flex
      align="center"
      :mx="{ base: '0', md: '0', xs: '5' }"
      justify="space-between"
      mb="8"
    >
      <c-flex align="baseline">
        <AppSegment
          :segments="segments"
          name="view"
          @input="handleChange"
          v-model="currentView"
        />
      </c-flex>
      <HeaderOptions />
    </c-flex>
    <c-box mb="8">
      <div v-show="currentView == 'timeline'">
        <TimelineCard
          @editMilestone="onEditMilestone"
          @setAchieved="onSetAsAchieved"
          @setInProgress="onSetAsInProgress"
          @addItem="onAddItem"
        />
        <c-box mb="4">
          <MilestonesCard
            @addItem="onAddItem"
            @editMilestone="onEditMilestone"
            @setAchieved="onSetAsAchieved"
          />
        </c-box>
      </div>

      <Traction
        :isMarketPlan="true"
        :type="'market_goal'"
        v-show="currentView == 'market-goal'"
      />

      <Traction v-show="currentView == 'traction'" :type="'traction'" />
    </c-box>

    <c-modal
      :is-open="isItemModalOpen"
      :on-close="onModalClose"
      is-centered
      size="xl"
      zIndex="modal"
    >
      <c-modal-content ref="content" zIndex="modal">
        <c-modal-header>
          <c-flex>
            {{ isEditMode ? 'Edit' : 'Add' }}
            <c-text textTransform="capitalize" ml="1">
              {{ itemAddType }}
            </c-text>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <form @submit.prevent="onItemFormSubmit">
          <c-modal-body>
            <c-box p="3">
              <template v-if="itemAddType === 'milestone'">
                <c-box mb="5">
                  <c-input
                    size="lg"
                    placeholder="Milestone Name"
                    v-model="$v.itemForm.title.$model"
                  ></c-input>
                </c-box>
                <c-box mb="5">
                  <AppDatePicker
                    format="YYYY-MM-DD"
                    type="date"
                    v-model="$v.itemForm.date.$model"
                  >
                    <template v-slot:input>
                      <c-input
                        size="lg"
                        placeholder="Select Date"
                        v-model="milestoneDateDisplay"
                      ></c-input>
                    </template>
                  </AppDatePicker>
                </c-box>
                <c-box mb="5">
                  <AppSelect
                    label="title"
                    :reduce="(scenario) => scenario.id"
                    :options="scenarios"
                    :reset="true"
                    :fullWidth="true"
                    :bordered="true"
                    indicator-fill="orange"
                    color="dark"
                    :borderColor="'light'"
                    size="large"
                    v-model="$v.itemForm.scenarioId.$model"
                    placeholder="Add Scenario (optional)"
                  />
                </c-box>
                <c-box mb="5">
                  <CategorySelect v-model="$v.itemForm.categoryId.$model" />
                  <!-- <AppSelect
                    label="title"
                    :reduce="(category) => category.id"
                    :options="categories"
                    :reset="true"
                    :fullWidth="true"
                    :bordered="true"
                    indicator-fill="orange"
                    color="dark"
                    v-model="$v.itemForm.categoryId.$model"
                    :borderColor="'light'"
                    size="large"
                    placeholder="Add Category"
                  /> -->
                </c-box>
                <c-box>
                  <textarea
                    v-model="$v.itemForm.description.$model"
                    placeholder="Description"
                    v-chakra="{
                      w: '100%',
                      h: '2rem',
                      outline: 'none',
                      borderRadius: '0.25rem',
                      borderWidth: '1px',
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      paddingLeft: '1rem',
                      paddingRight: '1rem',
                      fontSize: '1rem',
                      minHeight: '80px',
                    }"
                  ></textarea>
                </c-box>
              </template>
              <template v-else-if="itemAddType === 'phase'">
                <c-box mb="5">
                  <c-input
                    size="lg"
                    placeholder="Phase Name"
                    v-model="$v.itemForm.title.$model"
                  ></c-input>
                </c-box>
                <c-flex mb="5" justify="space-between">
                  <c-box w="40%">
                    <AppDatePicker
                      format="YYYY-MM-DD"
                      type="date"
                      v-model="$v.itemForm.from.$model"
                    >
                      <template v-slot:input>
                        <c-input
                          size="lg"
                          placeholder="Select Date"
                          v-model="fromPhaseDateDisplay"
                        ></c-input>
                      </template>
                    </AppDatePicker>
                  </c-box>
                  <c-box w="40%">
                    <AppDatePicker
                      format="YYYY-MM-DD"
                      type="date"
                      v-model="$v.itemForm.to.$model"
                    >
                      <template v-slot:input>
                        <c-input
                          size="lg"
                          placeholder="Select Date"
                          v-model="toPhaseDateDisplay"
                        ></c-input>
                      </template>
                    </AppDatePicker>
                  </c-box>
                </c-flex>
                <c-box mb="5">
                  <CategorySelect v-model="$v.itemForm.categoryId.$model" />
                  <!-- <AppSelect
                    :options="categories"
                    label="title"
                    :reduce="(category) => category.id"
                    :reset="true"
                    :fullWidth="true"
                    :bordered="true"
                    indicator-fill="orange"
                    color="dark"
                    :borderColor="'light'"
                    size="large"
                    v-model="$v.itemForm.categoryId.$model"
                    placeholder="Add Category"
                  /> -->
                </c-box>
                <c-box>
                  <c-textarea
                    v-model="$v.itemForm.description.$model"
                    placeholder="Description"
                  ></c-textarea>
                </c-box>
              </template>
              <template v-else>
                <c-box mb="5">
                  <c-input
                    size="lg"
                    v-model="$v.itemForm.title.$model"
                    placeholder="Category Name"
                  ></c-input>
                </c-box>
              </template>
            </c-box>
          </c-modal-body>
          <c-modal-footer>
            <c-button
              :disabled="$v.itemForm.$invalid || isAddingItem"
              variant-color="vc-orange"
              type="submit"
              mr="3"
            >
              <c-text color="#fff">
                {{ isEditMode ? 'Edit' : 'Create' }}
              </c-text>
              <c-spinner
                v-if="isAddingItem"
                ml="2"
                color="#fff"
                thickness="2px"
              />
            </c-button>
          </c-modal-footer>
        </form>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
    <c-alert-dialog
      :is-open="isAchievedDialogOpen"
      :on-close="closeAchievedDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold">
          Achieved Milestone
        </c-alert-dialog-header>
        <c-alert-dialog-body>
          Set Date Milestone was acheived
          <AppDatePicker
            format="YYYY-MM-DD"
            type="date"
            v-model="tempAchievedMilestoneDate"
          >
            <template v-slot:input>
              <c-input
                size="lg"
                readonly
                placeholder="Select Date"
                :value="achievedMilestoneDateDisplay"
              ></c-input>
            </template>
          </AppDatePicker>
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeAchievedDialog">
            Cancel
          </c-button>
          <c-button
            :disabled="!tempAchievedMilestoneDate"
            variantColor="vue"
            @click="saveMilestoneAsAchieved"
            ml="3"
          >
            Save
            <c-spinner
              ml="2"
              v-if="isMarkingAsAchieved"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
    <c-alert-dialog
      :is-open="isInProgressDialogOpen"
      :on-close="closeInProgressDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold"
          >Ongoing Milestone</c-alert-dialog-header
        >
        <c-alert-dialog-body>
          Set Milestone status to 'IN PROGRESS'
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeInProgressDialog"
            >Cancel</c-button
          >
          <c-button
            variantColor="vue"
            @click="saveMilestoneAsInProgress"
            ml="3"
          >
            Save
            <c-spinner
              ml="2"
              v-if="isMarkingAsInProgress"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
    <share-content
      :isShareOpen="isShareOpen"
      @close="onShareClick"
      :url="``"
      :shareTitle="'Congratulations! Share your progress'"
      :postTitle="'Hi, just completed my goal on VibrantCreaor'"
      :postDescription="'Click the link to squash your goals too!'"
    />
  </c-box>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import AppSegment from '../GrowthFramework/components/AppSegment.vue';
import Traction from '../GrowthFramework/components/market-potential/Traction.vue';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  addCategory,
  addMilestone,
  addPhase,
  getCategories,
  updateMilestone,
  getMilestoneData,
  updatePhase,
  subscribeToMilestoneData,
} from '@/services/timeline';

import AppSelect from '../components/AppSelect.vue';
import AppDatePicker from '../components/AppDatePicker.vue';
import ShareContent from './../components/ShareContent';
import MilestonesCard from './components/MilestonesCard.vue';
import TimelineCard from './components/TimelineCard.vue';
import HeaderOptions from '@/views/App/components/HeaderOptions.vue';
import cloneDeep from 'lodash.clonedeep';
import CategorySelect from './components/CategorySelect.vue';

export default {
  components: {
    TimelineCard,
    MilestonesCard,
    AppSegment,
    Traction,
    AppSelect,
    AppDatePicker,
    HeaderOptions,
    CategorySelect,
    ShareContent,
  },
  validations: {
    itemForm: {
      title: {
        required,
      },
      date: {
        required: requiredIf(function () {
          return this.itemAddType === 'milestone';
        }),
      },
      from: {
        required: requiredIf(function () {
          return this.itemAddType === 'phase';
        }),
      },
      to: {
        required: requiredIf(function () {
          return this.itemAddType === 'phase';
        }),
      },
      description: {},
      categoryId: {},
      scenarioId: {},
    },
  },
  data() {
    return {
      currentView: 'timeline',
      segments: [
        { title: 'Timeline', value: 'timeline' },
        { title: 'Market Goals', value: 'market-goal' },
        { title: 'Traction', value: 'traction' },
      ],
      isShareOpen: false,
      isItemModalOpen: false,
      itemAddType: '',
      milestoneDate: new Date(),
      fromPhaseDate: new Date(),
      toPhaseDate: new Date(),
      itemForm: {
        title: null,
        date: new Date(),
        from: new Date(),
        to: new Date(),
        description: null,
        categoryId: null,
        scenarioId: null,
      },
      isAddingItem: false,
      isEditMode: false,
      isAchievedDialogOpen: false,
      isInProgressDialogOpen: false,
      tempAchievedMilestone: null,
      tempInProgressMilestone: null,
      tempAchievedMilestoneDate: null,
      isMarkingAsAchieved: false,
      isMarkingAsInProgress: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapState('timeline', {
      categories: (state) => state.categories,
      scenarios: (state) => state.scenarios,
      milestones: (state) => state.milestones,
      phases: (state) => state.phases,
    }),
    milestoneDateDisplay() {
      return this.itemForm.date
        ? this.$moment(this.itemForm.date).format('YYYY-MM-DD')
        : null;
    },
    fromPhaseDateDisplay() {
      return this.itemForm.from
        ? this.$moment(this.itemForm.from).format('YYYY-MM-DD')
        : null;
    },
    toPhaseDateDisplay() {
      return this.itemForm.to
        ? this.$moment(this.itemForm.to).format('YYYY-MM-DD')
        : null;
    },
    achievedMilestoneDateDisplay() {
      return this.formatDate(this.tempAchievedMilestoneDate);
    },
  },
  created() {
    this.onGetTimelineData();
  },
  mounted() {
    this.subscribeToMilestoneTimeline();
  },
  methods: {
    ...mapMutations({
      addCategory: 'timeline/addCategory',
      setCategories: 'timeline/setCategories',
      setScenarios: 'timeline/setScenarios',
      setPhases: 'timeline/setPhases',
      setMilestones: 'timeline/setMilestones',
      addNewPhase: 'timeline/addPhase',
      addNewCategory: 'timeline/addCategory',
      addNewMilestone: 'timeline/addMilestone',
      addNewScenario: 'timeline/addScenario',
    }),
    ...mapActions({
      updateMilestoneInStore: 'timeline/updateMilestone',
      deleteMilestoneInStore: 'timeline/deleteMilestone',
    }),
    handleChange(s) {
      console.log(s);
      this.currentView = '';
      this.currentView = s;
    },
    formatDate(date) {
      return date && this.$moment(date).isValid()
        ? this.$moment(date).format('YYYY-MM-DD')
        : null;
    },
    onGetTimelineData() {
      getMilestoneData(this.activeCompany.id).then((res) => {
        const company = res.data.company[0];
        this.setCategories(company.calender_categories);
        this.setPhases(company.phases);
        this.setScenarios(company.scenarios);
        this.setMilestones(company.milestones);
      });
    },
    subscribeToMilestoneTimeline() {
      subscribeToMilestoneData(this.activeCompany.id).subscribe({
        next: (res) => {
          const company = res.data.company[0];
          this.setCategories(company.calender_categories);
          this.setPhases(company.phases);
          this.setScenarios(company.scenarios);
          this.setMilestones(company.milestones);
        },
        error(error) {
          console.error(error);
        },
      });
    },
    getCategories() {
      getCategories(this.activeCompany.id).then((res) => {
        this.setCategories(res.data.calender_category);
      });
    },
    onAddItem(arg) {
      if (arg.payload) {
        const currentPhase = this.phases.find(
          (phase) => phase.title === arg.payload.title
        );
        this.itemForm = cloneDeep(currentPhase);
        this.isEditMode = true;
      }
      this.isItemModalOpen = true;
      this.itemAddType = arg.type;
    },
    onModalClose() {
      this.isItemModalOpen = false;
      this.itemAddType = null;
      this.isAddingItem = false;
      this.clearItemForm();
    },
    onInput(value) {
      console.log(value);
      this.milestoneDate = value;
    },
    onItemFormSubmit() {
      this.isAddingItem = true;
      switch (this.itemAddType) {
        case 'category': {
          this.addCategory();
          break;
        }
        case 'phase': {
          if (this.isEditMode) {
            this.updatePhases();
          } else {
            this.addPhase();
          }

          break;
        }
        case 'milestone': {
          if (this.isEditMode) {
            this.updateMilestone();
          } else {
            this.addMilestone();
          }
          break;
        }
        default:
          break;
      }
    },
    async addCategory() {
      const { title } = this.itemForm;
      const data = {
        title,
        companyId: this.activeCompany.id,
      };
      try {
        const res = await addCategory(data);
        this.addNewCategory(res.data.insert_calender_category_one);
        this.clearItemForm();
        this.isAddingItem = false;
        this.onModalClose();
      } catch (e) {
        const err = Object.assign({}, e);
        // check if err.message has permission denied
        if (err.message.includes('permission')) {
          this.$toast({
            title: 'Access Denied.',
            description: `You don't have permission to add category.`,
            status: 'warning',
            position: 'top',
            duration: 4000,
          });
        } else {
          this.$toast({
            title: 'An error occurred.',
            description: `Error while adding timeline category, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
        this.isAddingItem = false;
      }
    },
    async addPhase() {
      const { title, from, to, description, categoryId } = this.itemForm;
      const data = {
        title,
        categoryId,
        description,
        from: from.toISOString(),
        to: to.toISOString(),
        companyId: this.activeCompany.id,
      };
      try {
        const res = await addPhase(data);
        this.addNewPhase(res.data.insert_phase_one);
        this.clearItemForm();
        this.isAddingItem = false;
        this.onModalClose();
      } catch (e) {
        this.isAddingItem = false;
        const err = Object.assign({}, e);
        // check if err.message has permission denied
        if (err.message.includes('permission')) {
          this.$toast({
            title: 'Access Denied.',
            description: `You don't have permission to add category.`,
            status: 'warning',
            position: 'top',
            duration: 4000,
          });
        } else {
          this.$toast({
            title: 'An error occurred.',
            description: `Error while adding timeline category, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    async updatePhases() {
      const { id, title, from, to, description, categoryId } = this.itemForm;

      try {
        await updatePhase({
          id: this.itemForm.id,
          set: {
            title,
            id,
            categoryId,
            description,
            from,
            to,
          },
        });

        this.clearItemForm();
        this.isAddingItem = false;
        this.isEditMode = false;
        this.onModalClose();
      } catch (e) {
        this.isAddingItem = false;
        this.isEditMode = false;
        this.onModalClose();
      }
    },
    async addMilestone() {
      const { title, date, description, categoryId, scenarioId } =
        this.itemForm;
      const data = {
        title,
        categoryId,
        description,
        scenarioId,
        date: date.toISOString(),
        companyId: this.activeCompany.id,
      };
      try {
        const res = await addMilestone(data);
        this.addNewMilestone(res.data.insert_milestone_one);
        this.clearItemForm();
        this.isAddingItem = false;
        this.onModalClose();
      } catch (e) {
        this.isAddingItem = false;
        const err = Object.assign({}, e);
        // check if err.message has permission denied
        if (err.message.includes('permission')) {
          this.$toast({
            title: 'Access Denied.',
            description: `You don't have permission to add category.`,
            status: 'warning',
            position: 'top',
            duration: 4000,
          });
        } else {
          this.$toast({
            title: 'An error occurred.',
            description: `Error while adding milestone to the timeline, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    clearItemForm() {
      this.itemForm = {
        title: null,
        date: new Date(),
        from: new Date(),
        to: new Date(),
        description: null,
        categoryId: null,
        scenarioId: null,
      };
    },
    onEditMilestone(milestone) {
      this.itemAddType = 'milestone';
      if (!milestone.isDeleted) {
        this.isEditMode = true;
        this.isItemModalOpen = true;
      }
      this.itemForm = { ...milestone };
      if (milestone.isDeleted) {
        this.updateMilestone();
      }
    },
    async updateMilestone() {
      const { title, date, description, categoryId, scenarioId, isDeleted } =
        this.itemForm;

      try {
        const res = await updateMilestone({
          id: this.itemForm.id,
          set: {
            title,
            date,
            description,
            categoryId,
            scenarioId,
            isDeleted,
          },
        });
        if (!isDeleted) {
          this.updateMilestoneInStore(
            cloneDeep(res.data.update_milestone_by_pk)
          );
        } else {
          this.deleteMilestoneInStore(this.itemForm);
        }
        this.clearItemForm();
        this.isAddingItem = false;
        this.isEditMode = false;
        this.onModalClose();
      } catch (e) {
        if (!isDeleted) {
          this.updateMilestoneInStore(this.itemForm);
        }
        this.isAddingItem = false;
        this.isEditMode = false;
      }
    },
    onSetAsAchieved(milestone) {
      this.tempAchievedMilestone = { ...milestone };
      this.isAchievedDialogOpen = true;
    },
    onSetAsInProgress(milestone) {
      this.tempInProgressMilestone = { ...milestone };
      this.isInProgressDialogOpen = true;
    },
    onShareClick() {
      this.isShareOpen = !this.isShareOpen;
    },
    async saveMilestoneAsAchieved() {
      this.isMarkingAsAchieved = true;
      try {
        const res = await updateMilestone({
          id: this.tempAchievedMilestone.id,
          set: {
            dateAchieved: this.tempAchievedMilestoneDate,
            status: 'ACHIEVED',
          },
        });
        this.updateMilestoneInStore(cloneDeep(res.data.update_milestone_by_pk));
        this.isMarkingAsAchieved = false;
        this.closeAchievedDialog();
        this.onShareClick();
      } catch (e) {
        console.log({ e });
      }
    },
    async saveMilestoneAsInProgress() {
      this.isMarkingAsInProgress = true;
      const res = await updateMilestone({
        id: this.tempInProgressMilestone.id,
        set: {
          dateAchieved: null,
          status: 'IN_PROGRESS',
        },
      });
      this.updateMilestoneInStore(cloneDeep(res.data.update_milestone_by_pk));
      this.isMarkingAsInProgress = false;
      this.closeInProgressDialog();
    },
    closeAchievedDialog() {
      this.isAchievedDialogOpen = false;
      this.tempAchievedMilestone = null;
      this.tempAchievedMilestoneDate = null;
    },
    closeInProgressDialog() {
      this.isInProgressDialogOpen = false;
      this.tempInProgressMilestone = null;
    },
  },
};
</script>

<style></style>
