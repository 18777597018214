<template>
  <c-flex w="full" h="full">
    <c-flex
      w="30%"
      h="full"
      direction="column"
      bgImage="linear-gradient(210deg, #1a86d0 0%, #103eba 100%)"
    >
      <c-box w="50%" mt="6" mx="auto">
        <img
          class=""
          v-chakra
          w="100%"
          src="@/assets/img/logo-white.png"
          alt="Vibrant Creator Logo"
        />
      </c-box>
      <c-stack px="8" w="full" mt="auto" :spacing="3" mb="auto">
        <c-heading color="#fff" as="h4" size="lg"> VIBRANTFOUNDER! </c-heading>
        <c-text color="#fff">
          Your number one business partner! <br />
          It’s free and will always be that that way!
        </c-text>
      </c-stack>
    </c-flex>
    <c-flex flex="1" align="center" position="relative">
      <c-link
        position="absolute"
        right="60px"
        top="30px"
        color="vc-orange.400"
        textDecoration="none"
        @click="onLogoutClick"
      >
        Log out
      </c-link>
      <c-stack mx="auto" :spacing="8">
        <c-heading as="h1" size="xl">
          Welcome
          <c-text ml="5px" color="#1a86d0" as="span">
            {{ user ? user.firstname : 'Name' }}
            {{ user ? user.lastname : 'Name' }}
          </c-text>
        </c-heading>
        <c-text color="#929ba1"> You're not a member of any team </c-text>
        <c-text color="#929ba1">
          Our goal is to help you build a strong business and maximise your
          potential
        </c-text>
        <c-text color="#929ba1"> Think of us as your business partner </c-text>
        <c-button
          borderRadius="lg"
          size="md"
          variant="outline"
          color="#ef5323"
          variant-color="#ef5323"
          h="50px"
          class="welcome__button"
          @click="startOnboarding"
        >
          Now let’s get you started
        </c-button>
      </c-stack>
    </c-flex>
  </c-flex>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { onLogout } from '@/vue-apollo.js';

export default {
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      resetCompanyState: 'company/resetCompanyState',
      resetTimeline: 'timeline/resetTimeline',
    }),
    startOnboarding() {
      this.$router.push({ name: 'Onboarding', query: { new_company: true } });
    },
    async onLogoutClick() {
      this.logout();
      await onLogout(this.$apollo.provider.defaultClient);
      this.$router.push('/auth/login');
      this.resetCompanyState(null);
      this.resetTimeline();
    },
  },
};
</script>

<style></style>
