import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=5735ca70&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=5735ca70&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5735ca70",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CBox: require('@chakra-ui/vue').CBox, CIcon: require('@chakra-ui/vue').CIcon, CIconButton: require('@chakra-ui/vue').CIconButton, CText: require('@chakra-ui/vue').CText})
