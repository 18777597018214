import {
  attendEventMutation,
  createEventMutation,
  createEventPostMutation,
  leaveEventMutation, updateEventMutation, deletingEvent
} from '@/graphql/mutations';
import { apolloInstance } from '@/vue-apollo';
import { getPostsQuery } from '@/graphql/queries';
import {
  aggregateEventAttendees,
  getEventsQuery,
  retrieveEventAttendees,
  getEventsAttendedByUserQuery,
} from '@/graphql/queries/event';
import { getEventByIdQuery } from '@/graphql/queries/event';

export const createEvent = (data) => {
  return apolloInstance.mutate({
    mutation: createEventMutation,
    variables: { object: data },
  });
};

export const createEventPost = (data) => {
  return apolloInstance.mutate({
    mutation: createEventPostMutation,
    fetchPolicy: 'no-cache',
    variables: { object: data },
  });
};

export const attendEvent = (data) => {
  return apolloInstance.mutate({
    mutation: attendEventMutation,
    variables: { object: data },
  });
};

export const leaveEvent = ({ eventId, attendeeId }) => {
  return apolloInstance.mutate({
    mutation: leaveEventMutation,
    variables: { eventId, attendeeId },
  });
};

export const getEvents = ({ limit, offset }) => {
  return apolloInstance.query({
    query: getEventsQuery,
    fetchPolicy: 'no-cache',
    variables: { limit, offset },
  });
};

export const getEventAttendeesAggregate = ({ eventId }) => {
  return apolloInstance.query({
    query: aggregateEventAttendees,
    fetchPolicy: 'no-cache',
    variables: { eventId: eventId },
  });
};

export const getEventAttendees = ({ eventId, limit }) => {
  return apolloInstance.query({
    query: retrieveEventAttendees,
    fetchPolicy: 'no-cache',
    variables: { eventId, limit },
  });
};
export const getEventById = ({ id }) => {
  return apolloInstance.query({
    query: getEventByIdQuery,
    fetchPolicy: 'no-cache',
    variables: { id: id },
  });
};

export const getPosts = ({ userId, limit, offset }) => {
  return apolloInstance.query({
    query: getPostsQuery,
    fetchPolicy: 'no-cache',
    variables: { userId, limit, offset },
  });
};

export const getEventsAttendedByUser = (loggedInUserId) => {
  return apolloInstance.query({
    query: getEventsAttendedByUserQuery,
    fetchPolicy: 'no-cache',
    variables: { loggedInUserId },
  });
};

export const updateEvent = ({eventId, updateEventObj, newSpeaker}) => {
  return apolloInstance.mutate({
    mutation: updateEventMutation,
    variables: { eventId, updateEventObj, newSpeaker }
  })
};

export const deleteEvent = ({eventId, userId}) => {
  return apolloInstance.mutate({
    mutation: deletingEvent,
    variables: { eventId, userId }
  })
};
