import { gql } from '@apollo/client/core';

export const getMatchingUsersQuery = gql`
  query getMatchingUsersQuery($text: String!) {
    user(
      where: {
        isVerified: { _eq: true }
        _or: [{ firstname: { _ilike: $text } }, { lastname: { _ilike: $text } }]
      }
    ) {
      id
      email
      firstname
      lastname
      profilePhoto
      isVerified
      companies {
        id
        name
      }
    }
  }
`;

export const getIsFollowingCompany = gql`
  query followingCompany($userId: String!, $companyId: Int!) {
    company_follower(
      where: {
        follower: { id: { _eq: $userId } }
        company: { id: { _eq: $companyId } }
      }
    ) {
      follower {
        id
        firstname
        lastname
      }
    }
  }
`;

export const searchChannelQuery = gql`
  query SearchChannels(
    $search_param: String!
    $loggedInUserId: Int!
    $limit: Int!
    $offset: Int!
  ) {
    chat_channel(
      limit: $limit
      offset: $offset
      where: {
        _and: [
          { name: { _ilike: $search_param } }
          {
            _or: [
              { type: { _eq: OPEN } }
              {
                _and: [
                  { type: { _eq: CLOSE } }
                  { participants: { participant_id: { _eq: $loggedInUserId } } }
                ]
              }
            ]
          }
        ]
      }
    ) {
      name
      id
      __typename
      participants {
        id
        channel_id
        participant {
          id
          lastname
          firstname
        }
      }
    }
  }
`;

export const searchCompanyQuery = gql`
  query searchCompanyQuery(
    $search_param: String!
    $limit: Int!
    $offset: Int!
  ) {
    company(
      limit: $limit
      offset: $offset
      where: {
        _or: [
          { country: { _ilike: $search_param } }
          { city: { _ilike: $search_param } }
          { name: { _ilike: $search_param } }
        ]
      }
    ) {
      name
      id
      city
      country
      logo
      __typename
      company_followers_aggregate {
        aggregate {
          count
        }
      }
    }
    company_aggregate(
      where: {
        _or: [
          { country: { _ilike: $search_param } }
          { city: { _ilike: $search_param } }
          { name: { _ilike: $search_param } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const searchUserQuery = gql`
    query searchUserQuery($search_param: String!, $offset: Int!, $limit: Int!) {
        user_search(
            limit: $limit
            offset: $offset
            where: {
                _or: [
                    { email: { _ilike: $search_param } }
                    { firstname: { _ilike: $search_param } }
                    { lastname: { _ilike: $search_param } }
                    { fullname1: { _ilike: $search_param } }
                    { fullname2: { _ilike: $search_param } }
                    { companyName: { _ilike: $search_param } }
                ]
            }
        ) {
            id
            profilePhoto
            email
            lastname
            firstname
            OnlineStatus
            companyName
            companyLogo
            __typename
        }
    }
`;

export const getMatchingTagsQuery = gql`
  query getMatchingTagsQuery($text: String!) {
    tag(where: { name: { _ilike: $text } }) {
      createdAt
      id
      name
    }
  }
`;
export const getCompanyPostsQuery = gql`
  query getPostsQuery($companyId: Int!, $offset: Int!, $limit: Int!) {
    post(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
      where: { companyId: { _eq: $companyId }, isCompanyPost: { _eq: true } }
    ) {
      isCompanyPost
      attachments
      companyId
      isEdited
      company {
        id
        name
        logo
        customerSegments
        country
        createdAt
        createdBy
      }
      post_comments(where: { replyTo: { _is_null: true } }) {
        postId
        content
        createdAt
        id
        userId
        isEdited
        isDeleted
        user {
          firstname
          id
          email
          profilePhoto
          lastname
        }
        replies {
          content
          createdAt
          id
          postId
          user {
            firstname
            email
            id
            lastname
            profilePhoto
          }
          userId
        }
      }
      post_likes {
        id
        userId
        user {
          firstname
          id
          email
          lastname
          profilePhoto
        }
      }
      userId
      user {
        firstname
        email
        lastname
        id
        createdAt
        profilePhoto
      }
      content
      created_at
      id
      updated_at
      post_tags {
        id
        tag {
          name
        }
        createdAt
      }
      post_saves(where: { post: { isDeleted: { _eq: false } } }) {
        userId
        postId
        post {
          id
        }
      }
    }
    post_aggregate {
      aggregate {
        count
      }
    }
    post_save {
      id
      post {
        attachments
        companyId
        company {
          id
          name
          logo
          customerSegments
          country
          createdAt
          createdBy
        }
        post_comments(where: { replyTo: { _is_null: true } }) {
          postId
          content
          createdAt
          id
          isEdited
          isDeleted
          user {
            firstname
            id
            email
            profilePhoto
            lastname
          }
          replies {
            content
            createdAt
            id
            postId
            user {
              firstname
              email
              id
              lastname
              profilePhoto
            }
            userId
          }
        }
        post_likes {
          id
          userId
          user {
            firstname
            id
            email
            lastname
            profilePhoto
          }
        }
        userId
        user {
          firstname
          email
          lastname
          id
          createdAt
          profilePhoto
        }
        content
        created_at
        id
        post_tags {
          id
          tag {
            name
          }
          createdAt
        }
        post_saves {
          userId
          postId
          post {
            id
          }
        }
      }
    }
  }
`;

export const getUsersPostsQuery = gql`
  query getUserPostsQuery($userId: Int!, $offset: Int!, $limit: Int!) {
    post(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
      where: { userId: { _eq: $userId } }
    ) {
      content
      companyId
      created_at
      updated_at
      id
      isEdited
      eventId
      isCompanyPost
      attachments
      postType
      userId
      company {
        id
        name
        logo
        customerSegments
        country
        createdAt
        createdBy
      }
      post_comments(where: { replyTo: { _is_null: true } }) {
        postId
        content
        createdAt
        id
        userId
        isEdited
        isDeleted
        user {
          firstname
          id
          email
          profilePhoto
          lastname
        }
        replies {
          content
          createdAt
          id
          postId
          user {
            firstname
            email
            id
            lastname
            profilePhoto
          }
          userId
        }
      }
      post_likes {
        id
        userId
        user {
          firstname
          id
          email
          lastname
          profilePhoto
        }
      }
      userId
      user {
        firstname
        email
        lastname
        id
        createdAt
        profilePhoto
      }
      post_tags {
        id
        tag {
          name
        }
        createdAt
      }
      post_saves(
        where: { userId: { _eq: $userId }, post: { isDeleted: { _eq: false } } }
      ) {
        userId
        postId
        post {
          id
        }
      }
    }
  }
`;
export const getPostsQuery = gql`
  query getPostsQuery($userId: Int!, $offset: Int!, $limit: Int!) {
    post(order_by: { created_at: desc }, limit: $limit, offset: $offset) {
      content
      companyId
      created_at
      updated_at
      id
      eventId
      isCompanyPost
      isEdited
      attachments
      post_feeling
      mentions {
        companyId
        company {
          id
          name
          country
          createdAt
          createdBy
        }
        userId
        user {
          id
          firstname
          lastname
        }
      }
      postType
      userId
      company {
        id
        name
        logo
        customerSegments
        country
        createdAt
        createdBy
      }
      post_comments(where: { replyTo: { _is_null: true } }) {
        postId
        content
        createdAt
        id
        userId
        isEdited
        isDeleted
        user {
          firstname
          id
          email
          profilePhoto
          lastname
        }
        replies {
          content
          createdAt
          id
          postId
          user {
            firstname
            email
            id
            lastname
            profilePhoto
          }
          userId
        }
      }
      post_likes {
        id
        userId
        user {
          firstname
          id
          email
          lastname
          profilePhoto
        }
      }
      userId
      user {
        firstname
        email
        lastname
        id
        createdAt
        profilePhoto
      }
      post_tags {
        id
        tag {
          name
        }
        createdAt
      }
      post_saves(
        where: { userId: { _eq: $userId }, post: { isDeleted: { _eq: false } } }
      ) {
        userId
        postId
        post {
          id
        }
      }
    }
    post_aggregate {
      aggregate {
        count
      }
    }
    post_save(
      where: { userId: { _eq: $userId }, post: { isDeleted: { _eq: false } } }
    ) {
      id
      post {
        attachments
        companyId
        company {
          id
          name
          logo
          customerSegments
          country
          createdAt
          createdBy
        }
        post_comments(where: { replyTo: { _is_null: true } }) {
          postId
          content
          createdAt
          id
          isEdited
          isDeleted
          user {
            firstname
            id
            email
            profilePhoto
            lastname
          }
          replies {
            content
            createdAt
            id
            postId
            user {
              firstname
              email
              id
              lastname
              profilePhoto
            }
            userId
          }
        }
        post_likes {
          id
          userId
          user {
            firstname
            id
            email
            lastname
            profilePhoto
          }
        }
        userId
        user {
          firstname
          email
          lastname
          id
          createdAt
          profilePhoto
        }
        content
        created_at
        id
        updated_at
        post_tags {
          id
          tag {
            name
          }
          createdAt
        }
        post_saves(where: { userId: { _eq: $userId } }) {
          userId
          postId
          post {
            id
          }
        }
      }
    }
  }
`;

export const getValidationPostsQuery = gql`
  query getValidationPostsQuery($userId: Int!, $validation_field: String!) {
    post(
      where: {
        isValidation: { _eq: true }
        validation_field: { _eq: $validation_field }
        userId: { _eq: $userId }
      }
      limit: 1
      order_by: { created_at: desc }
    ) {
      content
      companyId
      created_at
      updated_at
      id
      eventId
      isCompanyPost
      isEdited
      attachments
      post_feeling
      mentions {
        companyId
        company {
          id
          name
          country
          createdAt
          createdBy
        }
        userId
        user {
          id
          firstname
          lastname
        }
      }
      postType
      userId
      company {
        id
        name
        logo
        customerSegments
        country
        createdAt
        createdBy
      }
      post_comments(where: { replyTo: { _is_null: true } }) {
        postId
        content
        createdAt
        id
        userId
        isEdited
        isDeleted
        user {
          firstname
          id
          email
          profilePhoto
          lastname
        }
        replies {
          content
          createdAt
          id
          postId
          user {
            firstname
            email
            id
            lastname
            profilePhoto
          }
          userId
        }
      }
      post_likes {
        id
        userId
        user {
          firstname
          id
          email
          lastname
          profilePhoto
        }
      }
      userId
      user {
        firstname
        email
        lastname
        id
        createdAt
        profilePhoto
      }
      post_tags {
        id
        tag {
          name
        }
        createdAt
      }
      post_saves(
        where: { userId: { _eq: $userId }, post: { isDeleted: { _eq: false } } }
      ) {
        userId
        postId
        post {
          id
        }
      }
    }
    post_aggregate {
      aggregate {
        count
      }
    }
    post_save(
      where: { userId: { _eq: $userId }, post: { isDeleted: { _eq: false } } }
    ) {
      id
      post {
        attachments
        companyId
        company {
          id
          name
          logo
          customerSegments
          country
          createdAt
          createdBy
        }
        post_comments(where: { replyTo: { _is_null: true } }) {
          postId
          content
          createdAt
          id
          isEdited
          isDeleted
          user {
            firstname
            id
            email
            profilePhoto
            lastname
          }
          replies {
            content
            createdAt
            id
            postId
            user {
              firstname
              email
              id
              lastname
              profilePhoto
            }
            userId
          }
        }
        post_likes {
          id
          userId
          user {
            firstname
            id
            email
            lastname
            profilePhoto
          }
        }
        userId
        user {
          firstname
          email
          lastname
          id
          createdAt
          profilePhoto
        }
        content
        created_at
        id
        updated_at
        post_tags {
          id
          tag {
            name
          }
          createdAt
        }
        post_saves(where: { userId: { _eq: $userId } }) {
          userId
          postId
          post {
            id
          }
        }
      }
    }
  }
`;

export const getPostQuery = gql`
  query getPostQuery($id: Int!, $userId: Int!) {
    post(where: { id: { _eq: $id } }) {
      content
      companyId
      created_at
      updated_at
      id
      eventId
      isCompanyPost
      attachments
      postType
      userId
      company {
        id
        name
        logo
        customerSegments
        country
        createdAt
        createdBy
      }
      post_comments(where: { replyTo: { _is_null: true } }) {
        postId
        content
        createdAt
        id
        userId
        isEdited
        isDeleted
        user {
          firstname
          id
          email
          profilePhoto
          lastname
        }
        replies {
          content
          createdAt
          id
          postId
          user {
            firstname
            email
            id
            lastname
            profilePhoto
          }
          userId
        }
      }
      post_likes {
        id
        userId
        user {
          firstname
          id
          email
          lastname
          profilePhoto
        }
      }
      userId
      user {
        firstname
        email
        lastname
        id
        createdAt
        profilePhoto
      }
      post_tags {
        id
        tag {
          name
        }
        createdAt
      }
      post_saves(
        where: { userId: { _eq: $userId }, post: { isDeleted: { _eq: false } } }
      ) {
        userId
        postId
        post {
          id
        }
      }
    }
    post_aggregate {
      aggregate {
        count
      }
    }
    post_save(
      where: { userId: { _eq: $userId }, post: { isDeleted: { _eq: false } } }
    ) {
      id
      post {
        attachments
        companyId
        company {
          id
          name
          logo
          customerSegments
          country
          createdAt
          createdBy
        }
        post_comments(where: { replyTo: { _is_null: true } }) {
          postId
          content
          createdAt
          id
          isEdited
          isDeleted
          user {
            firstname
            id
            email
            profilePhoto
            lastname
          }
          replies {
            content
            createdAt
            id
            postId
            user {
              firstname
              email
              id
              lastname
              profilePhoto
            }
            userId
          }
        }
        post_likes {
          id
          userId
          user {
            firstname
            id
            email
            lastname
            profilePhoto
          }
        }
        userId
        user {
          firstname
          email
          lastname
          id
          createdAt
          profilePhoto
        }
        content
        created_at
        id
        updated_at
        post_tags {
          id
          tag {
            name
          }
          createdAt
        }
        post_saves(where: { userId: { _eq: $userId } }) {
          userId
          postId
          post {
            id
          }
        }
      }
    }
  }
`;

export const getSuggestedTagsQuery = gql`
  query getSuggestedTagsQuery($userId: Int!) {
    tag(order_by: { post_tags_aggregate: { count: desc } }, limit: 10) {
      name
      id
      createdAt
      userId
      post_tags_aggregate {
        aggregate {
          count
        }
      }
      follow_tags(where: { userId: { _eq: $userId } }) {
        id
      }
    }
  }
`;

export const getIsUserCompanyFollower = gql`
  query getIsUserCompanyFollower($companyId: Int!) {
    company_follower_by_pk(id: $companyId) {
      follower {
        id
        firstname
      }
    }
  }
`;


export const getSavedPosts = gql`
  query savePosts($userId:Int!, $offset: Int!, $limit: Int!) {
    post_save(where: {userId: {_eq: $userId}, post: { isDeleted: { _eq: false } }}
      offset: $offset
      limit: $limit
      order_by: {createdAt: desc}
    ) {
      id
      createdAt
      user {
        id
      }
      post{
        content
        companyId
        created_at
        updated_at
        id
        eventId
        isCompanyPost
        isEdited
        attachments
        post_feeling
        mentions {
          companyId
          company {
            id
            name
            country
            createdAt
            createdBy
          }
          userId
          user {
            id
            firstname
            lastname
          }
        }
        postType
        userId
        company {
          id
          name
          logo
          customerSegments
          country
          createdAt
          createdBy
        }
        post_comments(where: { replyTo: { _is_null: true } }) {
          postId
          content
          createdAt
          id
          userId
          isEdited
          isDeleted
          user {
            firstname
            id
            email
            profilePhoto
            lastname
          }
          replies {
            content
            createdAt
            id
            postId
            user {
              firstname
              email
              id
              lastname
              profilePhoto
            }
            userId
          }
        }
        post_likes {
          id
          userId
          user {
            firstname
            id
            email
            lastname
            profilePhoto
          }
        }
        userId
        user {
          firstname
          email
          lastname
          id
          createdAt
          profilePhoto
        }
        post_tags {
          id
          tag {
            name
          }
          createdAt
        }
        post_saves(
          where: { userId: { _eq: $userId }, post: { isDeleted: { _eq: false } } }
        ) {
          userId
          postId
          post {
            id
          }
        }
      }
    }
    post_save_aggregate(
      where: {userId: {_eq: $userId}, post: {isDeleted: {_eq: false}}}
    ) {
      aggregate {
        count
      }
    }
    
  }
`