<template>
  <c-box id="channels">
    <!-- :isLocked="!getCurrentPlan.access.channels" -->
    <TaskCard
      title="Channels"
      :description="''"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :hasMounted="hasMounted"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasAssumption="false"
      :taskData="businessModelData"
      :accessKey="'business_model_assignments'"
      :isLocked="!getCurrentPlan.access.channels"
      :accessFn="updateAccess"
      @update="updateData"
      :hasAccess="hasAccess"
      :visibilityKey="'business_model_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="channels"
      :disabled="disabled"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>

      <ChannelsList
        :channelsData="businessModelData"
        :topic="points[0].topic"
        :customer="customers[0]"
        :disabled="disabled"
        v-else
      />

      <!-- <ChannelsFields
        :disabled="disabled"
        :channelsData="businessModelData"
        v-else
      /> -->
    </TaskCard>
  </c-box>
</template>

<script>
import TaskCard from './../TaskCard.vue';
// import ChannelsFields from './ChannelsFields.vue';
import ChannelsList from './ChannelsList.vue';
import { ContentLoader } from 'vue-content-loader';

import businessModelMixin from '@/mixins/business-model.js';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [businessModelMixin],
  components: {
    ChannelsList,
    TaskCard,
    ContentLoader,
    // ChannelsFields,
  },
  data() {
    return {
      businessModelType: 'channels',
      hasMounted: false,
      points: [
        {
          title: 'Awareness',
          description:
            'Where do you make future customers aware of your product?',
          topic: 'awareness',
          isOpen: true,
        },
        {
          title: 'Evaluation',
          description:
            'Where does potential customers gain further knowledge about your product?',
          topic: 'evaluation',
          isOpen: false,
        },
        {
          title: 'Purchase',
          description: 'Where future customers be purchasing your product?',
          topic: 'purchase',
          isOpen: false,
        },
        {
          title: 'Delivery value',
          description:
            'Where do you deliver the promised value to your customer?',
          topic: 'delivery_value',
          isOpen: false,
        },
        {
          title: 'Post Sale Support',
          description:
            'Where will you be providing support for your customers?',
          topic: 'post_sale_support',
          isOpen: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
    ...mapState('company', {
      customers: (state) => state.customers,
    }),
  },
};
</script>

<style></style>
