import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=61ba3903&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=61ba3903&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ba3903",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CFormLabel: require('@chakra-ui/vue').CFormLabel, CCheckbox: require('@chakra-ui/vue').CCheckbox, CCheckboxGroup: require('@chakra-ui/vue').CCheckboxGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CDivider: require('@chakra-ui/vue').CDivider, CInput: require('@chakra-ui/vue').CInput, CStack: require('@chakra-ui/vue').CStack, CModalBody: require('@chakra-ui/vue').CModalBody, CButton: require('@chakra-ui/vue').CButton, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModal: require('@chakra-ui/vue').CModal, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CIconButton: require('@chakra-ui/vue').CIconButton})
