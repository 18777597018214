<template>
  <c-box style="position: relative">
    <c-flex
      class="header"
      align="['start','start', 'space-between']"
      :flexDirection="['column', 'column', 'row']"
      justify="space-between"
      mt="4"
      mb="4"
      :class="{'show-header-back': hideHeaderBackground === false, 'hide-header-back': hideHeaderBackground === true}"
    >
      <c-flex
        pt="10px"
        justify="['space-between','start', 'space-between']"
        align="center"
      >
        <c-heading
          pt="2"
          fontSize="2xl"
          fontWeight="700"
          mr="3"
          ml="3"
          :mb="['2', '2', '0']"
        >
          Explore
        </c-heading>
      </c-flex>
      <c-flex style="align-items: center; height: 50px" justify="['start','end', 'space-between']">
        <search @hide-sidebar="handleHideSidebar" />

        <HeaderOptions />
      </c-flex>
    </c-flex>

    <c-flex style="z-index: 99" :flexDirection="['column', 'column', 'row']">
      <c-flex w="70%" h="100%" :flex="hideSidebar ? '1' : '3'">
        <router-view />
      </c-flex>
      <c-flex v-if="!hideSidebar" ml="5" :flex="hideSidebar ? '0' : '3'">
        <sidebar />
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import HeaderOptions from '@/views/App/components/HeaderOptions.vue';
import Sidebar from './Sidebar.vue';
import Search from '@/views/App/Explore/Search.vue';
import {EventBus} from "@/eventBus";

export default {
  name: 'Layout',
  components: {
    Search,
    HeaderOptions,
    Sidebar,
  },
  data() {
    return {
      hideSidebar: false,
      hideHeaderBackground: false
    };
  },
  methods: {
    moveToSavedPage() {
      this.$router.push('/saved');
    },
    handleHideSidebar(status) {
      this.hideSidebar = status;
    },
  },
  created() {
    EventBus.$on('hideHeaderBackground', (payload) => {
      this.hideHeaderBackground = payload.status
    })
    if (
      this.$route.path === '/app/explore/search-landing' ||
      this.$route.path === '/app/explore/all-search-results'
    ) {
      console.log('Pepper Salt==>');
      this.handleHideSidebar(true);
    }
  },
  beforeDestroy() {
    EventBus.$off('hideHeaderBackground');
  },
  watch: {
    $route(to) {
      // Perform actions here
      if (to.path === '/app/explore' || to.path === '/app/explore/') {
        this.handleHideSidebar(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f9fbfd;
  padding-bottom: 20px;
}

.show-header-back{
  z-index: 88
}
.hide-header-back{
  z-index: 0
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .saved {
    position: absolute;
    right: 50px;
  }

  .menu {
    position: absolute;
    right: 10px;
    margin-top: 8px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .saved {
    right: 0;
  }

  .menu {
    display: none;
  }

  .header {
    position: sticky;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .menu {
    display: none;
  }
  .saved {
    top: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .menu {
    display: none;
  }
  .saved {
    top: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .menu {
    display: none;
  }
}

svg.svgmessage {
  width: 23px;
  height: 23px;
  fill: #6b7a84;
}

.custom_badge {
  position: absolute;
  top: 18px;
  right: 45px;
  background: #f00;
  color: #fff !important;
  border-radius: 50%;
  width: 18px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
}
</style>
