<template>
  <c-box id="key-activities">
    <!--       :isLocked="!getCurrentPlan.access.keyActivities" -->
    <TaskCard
      title="Key Activities"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :canAssign="true"
      :hasImportance="true"
      :hasMounted="hasMounted"
      :hasNotification="true"
      :hasVisibility="true"
      :hasAssumption="false"
      id="key-activities"
      :taskData="businessModelData"
      :accessKey="'business_model_assignments'"
      :accessFn="updateAccess"
      @update="updateData"
      :hasAccess="hasAccess"
      :visibilityKey="'business_model_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="key_activities"
      :disabled="disabled"
    >
      <template v-slot:description>
        <span>
          What key activities are required for you to deliver your value
          proposition?
        </span>
        <br />
        <span>
          What are the most important actions your company must perform in order
          to operate successfully?
        </span>
      </template>
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
      <KeyActivitiesFields
        :disabled="disabled"
        v-else
        :keyActivitiesData="businessModelData"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import TaskCard from '../TaskCard.vue';
import { ContentLoader } from 'vue-content-loader';

import KeyActivitiesFields from './KeyActivitiesFields.vue';

import businessModelMixin from '@/mixins/business-model.js';
import { mapGetters } from 'vuex';

export default {
  mixins: [businessModelMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TaskCard,
    ContentLoader,
    KeyActivitiesFields,
  },
  data() {
    return {
      businessModelType: 'key_activities',
      hasMounted: false,
    };
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
};
</script>

<style></style>
