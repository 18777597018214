import { render, staticRenderFns } from "./GroupSelect.vue?vue&type=template&id=40edb27c"
import script from "./GroupSelect.vue?vue&type=script&lang=js"
export * from "./GroupSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CIcon: require('@chakra-ui/vue').CIcon, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover})
