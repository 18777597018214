import { render, staticRenderFns } from "./PostCard.vue?vue&type=template&id=5d660405&scoped=true"
import script from "./PostCard.vue?vue&type=script&lang=js"
export * from "./PostCard.vue?vue&type=script&lang=js"
import style0 from "./PostCard.vue?vue&type=style&index=0&id=5d660405&prod&lang=scss"
import style1 from "./PostCard.vue?vue&type=style&index=1&id=5d660405&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d660405",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CIcon: require('@chakra-ui/vue').CIcon, CImage: require('@chakra-ui/vue').CImage, CBox: require('@chakra-ui/vue').CBox, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModal: require('@chakra-ui/vue').CModal, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CLink: require('@chakra-ui/vue').CLink, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CButton: require('@chakra-ui/vue').CButton, CSpinner: require('@chakra-ui/vue').CSpinner, CStack: require('@chakra-ui/vue').CStack, CGrid: require('@chakra-ui/vue').CGrid, CAvatar: require('@chakra-ui/vue').CAvatar, CAlertDialogOverlay: require('@chakra-ui/vue').CAlertDialogOverlay, CAlertDialogHeader: require('@chakra-ui/vue').CAlertDialogHeader, CAlertDialogBody: require('@chakra-ui/vue').CAlertDialogBody, CAlertDialogFooter: require('@chakra-ui/vue').CAlertDialogFooter, CAlertDialogContent: require('@chakra-ui/vue').CAlertDialogContent, CAlertDialog: require('@chakra-ui/vue').CAlertDialog})
