<template>
  <c-modal
    :is-open="openEditProfile"
    :on-close="close"
    :scroll-behavior="scrollBehavior"
    size="xl"
    is-centered
  >
    <c-modal-content ref="content">
      <c-modal-header>Edit your profile</c-modal-header>
      <c-modal-close-button />
      <c-divider border-color="gray.200" />
      <c-modal-body>
        <div>
          <c-text fontSize="sm" color="gray.400" fontWeight="600"
            >Personal</c-text
          >
          <br />
          <c-form-control>
            <c-form-label>First name</c-form-label>
            <c-input
              v-model="usersDetails.firstname"
              h="50px"
              borderRadius="8px"
              variant="filled"
              ref="initialRef"
              placeholder="First name"
            />
          </c-form-control>

          <c-form-control mt="4">
            <c-form-label>Last name</c-form-label>
            <c-input
              v-model="usersDetails.lastname"
              h="50px"
              borderRadius="8px"
              variant="filled"
              ref="initialRef"
              placeholder="Last name"
            />
          </c-form-control>

          <c-form-control mt="4">
            <c-form-label>Email</c-form-label>
            <c-input
              v-model="usersDetails.email"
              type="email"
              h="50px"
              borderRadius="8px"
              variant="filled"
              ref="initialRef"
              placeholder="Email"
            />
          </c-form-control>

          <c-form-control mt="4">
            <c-form-label>Phone number</c-form-label>
            <c-input
              v-model="usersDetails.phoneNumber"
              type="tel"
              h="50px"
              borderRadius="8px"
              variant="filled"
              ref="initialRef"
              placeholder="Phone number"
            />
          </c-form-control>

          <c-form-control mt="4">
            <c-textarea
              v-model="usersDetails.bio"
              :value="usersDetails.bio"
              type="text"
              h="150px"
              borderRadius="8px"
              variant="filled"
              ref="initialRef"
              placeholder="Let other know more about you"
            />
          </c-form-control>

<!--          <c-text fontSize="sm" mt="6" color="gray.400" fontWeight="600"-->
<!--            >Location</c-text-->
<!--          >-->
<!--          <br />-->
<!--          <c-form-control>-->
<!--            <c-form-label>Country</c-form-label>-->
<!--            <c-input-->
<!--              h="50px"-->
<!--              borderRadius="8px"-->
<!--              variant="filled"-->
<!--              ref="initialRef"-->
<!--              v-model="usersDetails.country"-->
<!--            />-->
<!--          </c-form-control>-->

<!--          <c-form-control mt="4">-->
<!--            <c-form-label>City</c-form-label>-->
<!--            <c-input-->
<!--              h="50px"-->
<!--              borderRadius="8px"-->
<!--              variant="filled"-->
<!--              ref="initialRef"-->
<!--              v-model="usersDetails.city"-->
<!--              placeholder="City"-->
<!--            />-->
<!--          </c-form-control>-->
        </div>
      </c-modal-body>
      <c-modal-footer>
        <c-button
          variant-color="vc-orange"
          @click="updateUserDate"
          :isLoading="btnLoading"
          >Save</c-button
        >
      </c-modal-footer>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import { updateUser } from '@/services/user';
import { mapGetters } from 'vuex';

export default {
  name: 'EditProfileModal',

  data() {
    return {
      scrollBehavior: 'inside',
      dateModel: null,
      btnLoading: false,
      openUp: false,
      usersDetails: {
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        dob: '',
        address: '',
        country: '',
        city: '',
        bio: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  props: {
    openEditProfile: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    updateUserDate() {
      this.btnLoading = true;
      updateUser({
        id: this.user.id,
        set: {
          firstname: this.usersDetails.firstname,
          lastname: this.usersDetails.lastname,
          phoneNumber: this.usersDetails.phoneNumber,
          email: this.usersDetails.email,
          bio: this.usersDetails.bio,
        },
      })
        .then((res) => {
          this.$toast({
            title: 'Success',
            description: `You have successfully updated your details.`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          console.log('Cashew==>',res.data)
          this.usersDetails = {...res.data.update_user_by_pk}
          this.$emit('updateComplete', this.usersDetails)
          this.close()
          this.btnLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            title: 'An error occurred.',
            description: `Could not update user. Try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
          this.btnLoading = false;
        });
    },
  },
  created() {
    this.usersDetails = {...this.userData}
  },
};
</script>
