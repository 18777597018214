import { render, staticRenderFns } from "./NewsCard.vue?vue&type=template&id=9a3651fa&scoped=true"
import script from "./NewsCard.vue?vue&type=script&lang=js"
export * from "./NewsCard.vue?vue&type=script&lang=js"
import style0 from "./NewsCard.vue?vue&type=style&index=0&id=9a3651fa&prod&lang=scss"
import style1 from "./NewsCard.vue?vue&type=style&index=1&id=9a3651fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a3651fa",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CAvatar: require('@chakra-ui/vue').CAvatar, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CLink: require('@chakra-ui/vue').CLink, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CBox: require('@chakra-ui/vue').CBox, CStack: require('@chakra-ui/vue').CStack, CGrid: require('@chakra-ui/vue').CGrid, CSpinner: require('@chakra-ui/vue').CSpinner, CButton: require('@chakra-ui/vue').CButton, CAlertDialogOverlay: require('@chakra-ui/vue').CAlertDialogOverlay, CAlertDialogHeader: require('@chakra-ui/vue').CAlertDialogHeader, CAlertDialogBody: require('@chakra-ui/vue').CAlertDialogBody, CAlertDialogFooter: require('@chakra-ui/vue').CAlertDialogFooter, CAlertDialogContent: require('@chakra-ui/vue').CAlertDialogContent, CAlertDialog: require('@chakra-ui/vue').CAlertDialog})
