<template>
  <c-box p="2">
    <c-flex justify="space-between" mb="3">
      <c-heading size="lg" as="h3" color="rgba(0,48,143)">
        Invoice List
      </c-heading>
      <c-box w="120px" size="sm">
        <c-select
            backgroundColor="tomato"
            borderColor="tomato"
            rounded="md"
            h="30px"
            fontSize="14px"
            color="white"
            v-model="paymentStatus"
            @change="filterInvoices"
        >
          <option value="all">All</option>
          <option value="paid">Paid</option>
          <option value="pending">Pending</option>
        </c-select>
      </c-box>
    </c-flex>

    <DataTable
      scrollable
      :value="filteredInvoices"
      class="table-body p-datatable-sm"
      v-if="filteredInvoices.length"
    >
      <template #header>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span class="datatable-header-title">Earnings Details</span>
          <c-input
            v-model="searchQuery"
            placeholder="Search..."
            @input="filterInvoices"
            style="width: 250px; padding: 8px; margin-left: auto"
          />
        </div>
      </template>
      <Column field="dateUsed" header="Date"></Column>
      <Column field="company.name" header="Business"></Column>
      <Column field="commissionPaymentStatus" header="Payment Status"></Column>
      <Column field="commissionAmount" header="Amount"></Column>
    </DataTable>

    <c-flex
      flexDirection="column"
      v-else
      justify="center"
      align="center"
      w="100%"
      h="100%"
    >
      <img
        style="z-index: 55"
        height="300px"
        width="300px"
        src="@/assets/img/empty.svg#empty-state"
        alt="empty state"
      />
      <c-text fontSize="15px" fontWeight="700" mt="50px"
        >No invoice data available</c-text
      >
    </c-flex>
  </c-box>
</template>

<script>
import DataTable from "primevue/datatable";
import { mapGetters } from "vuex";

export default {
  name: "InvoiceSummaryCard",
  components: {
    DataTable
  },
  props: ['invoiceData'],
  data() {
    return {
      searchQuery: "",
      paymentStatus: "all"  // Default filter shows all statuses
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "company/getActiveCompany"
    }),
    // Computed property to filter invoices based on paymentStatus and searchQuery
    filteredInvoices() {
      let filteredData = this.invoiceData;

      // Filter by payment status if not set to 'all'
      if (this.paymentStatus !== "all") {
        filteredData = filteredData.filter(
            invoice => invoice.commissionPaymentStatus.toLowerCase() === this.paymentStatus
        );
      }

      // Filter by search query if not empty
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filteredData = filteredData.filter(invoice =>
            // Check each property to see if it matches the search query
            Object.values(invoice).some(value =>
                String(value).toLowerCase().includes(query)
            )
        );
      }

      return filteredData;
    }
  },
  methods: {
    filterInvoices() {
      // No additional logic is needed here since filtering is handled by computed property
    }
  }
};
</script>

<style lang="scss">
.table th {
  border-radius: 3px;
  color: #1361ad;
  padding: 12px;
  text-align: left;
  font-size: 13px;
}

.table tr:nth-child(odd) {
  background-color: #f2faff;
}

.table tr:nth-child(even) {
  background-color: white;
}

.table td {
  font-size: 13px;
  padding: 10px;
}

.table tr:hover {
  background-color: #ddd;
}

.pagination button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.table-body {
  font-family: Montserrat;
  font-size: 13px;
}

.row button:hover {
  background-color: #45a049;
}

.pagination span {
  padding: 10px;
  color: #333;
}
</style>
