import { render, staticRenderFns } from "./PeopleCard.vue?vue&type=template&id=7b868d90&scoped=true"
import script from "./PeopleCard.vue?vue&type=script&lang=js"
export * from "./PeopleCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b868d90",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CButton: require('@chakra-ui/vue').CButton})
