<template>
  <Transition name="slide-fade">
    <c-box
      position="absolute"
      bottom="0"
      :right="{ lg: '2', xs: '0' }"
      py="4"
      zIndex="docked"
      class="footer_drawer_messages"
      v-show="isChatsOpen"
    >
      <template>
        <div class="quick_message">
          <div>
            <img
              :src="user.profilePhoto ? user.profilePhoto : placeHolderImage"
              alt="pic"
            />
            <span
              class="online_dot"
              v-show="user.OnlineStatus == 'true'"
            ></span>
          </div>

          <p @click="closeChatsDrawer()">Messaging</p>

          <span @click="openQuickMessage">
            <i class="far fa-plus-square"></i>
          </span>

          <span @click="closeChatsDrawer()">
            <i class="fas fa-chevron-up"></i>
          </span>
        </div>
        <div>
          <c-tabs>
            <c-tab-list>
              <c-tab style="font-size: 14px">Messages</c-tab>
              <c-tab style="font-size: 14px">Team</c-tab>
              <c-tab style="font-size: 14px">Channels</c-tab>
            </c-tab-list>

            <c-tab-panels>
              <c-tab-panel>
                <div class="list_container">
                  <UsersMessageList
                    @sendFooterMessage="sendQuickFooterMessage"
                  />
                </div>
              </c-tab-panel>
              <c-tab-panel>
                <div class="list_container">
                  <div
                    class="user"
                    v-for="member in members"
                    :key="member.id"
                    @click="sendQuickMessage(member.user)"
                  >
                    <img
                      :src="
                        member.user.profilePhoto
                          ? member.user.profilePhoto
                          : placeHolderImage
                      "
                      alt="profile"
                    />
                    <div class="details">
                      <div class="name_sec">
                        <h3>
                          {{ member.user.firstname }} {{ member.user.lastname }}
                        </h3>
                      </div>

                      <p>{{ member.user.email }}</p>
                    </div>
                  </div>
                </div>
              </c-tab-panel>
              <c-tab-panel>
                <div class="list_container">
                  <JoinedChannelsList
                    @sendFooterMessage="sendQuickFooterMessage"
                  />
                </div>
              </c-tab-panel>
            </c-tab-panels>
          </c-tabs>
        </div>
      </template>

      <UsersYouCanMessage
        @close="showQuickMessage = false"
        v-if="showQuickMessage"
      />
    </c-box>
  </Transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import messagingMixin from '@/mixins/messaging.js';
import UsersYouCanMessage from './UsersYouCanMessage.vue';
import UsersMessageList from './UsersMessageList.vue';
import placeHolderImage from '@/assets/img/profile_image.jpeg';
import JoinedChannelsList from './JoinedChannelsList.vue';

export default {
  name: 'ChatDrawer',
  mixins: [messagingMixin],
  components: {
    UsersMessageList,
    UsersYouCanMessage,
    JoinedChannelsList,
  },
  data() {
    return {
      assignedUsers: [],
      placeHolderImage,
      isChatsOpen: true,
      showQuickMessage: false,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('company', {
      members: (state) => state.members,
    }),
  },
  methods: {
    ...mapActions({
      addQuickChat: 'messaging/addQuickChat',
    }),
    sendQuickFooterMessage(selectedUser) {
      if (selectedUser.isChannel === true) {
        const user = {
          name: selectedUser.name,
          id: selectedUser.id,
          img: null,
          relationshipId: null,
          isChannel: true,
          messageTotal: selectedUser.messageTotal,
        };

        this.addQuickChat(user);
      } else {
        const user = {
          name: selectedUser.name,
          id: selectedUser.person2.id,
          img: selectedUser.person2.profilePhoto,
          relationshipId: selectedUser.id,
          isChannel: false,
          OnlineStatus: selectedUser.person2.OnlineStatus,
          messageTotal: selectedUser.messageTotal,
        };
        this.addQuickChat(user);
      }
    },
    closeChatsDrawer() {
      this.isChatsOpen = false;
    },
    openChatsDrawer() {
      this.isChatsOpen = true;
    },
    openQuickMessage() {
      this.showQuickMessage = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer_drawer_messages {
  background: #fff;
  height: calc(100vh - 100px);
  max-width: 300px;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 4px rgba(163, 162, 162, 0.25);
  // z-index: revert-layer;

  .msgTitle {
    /* Rectangle 88 */
    height: 20px;
    display: flex;
    align-items: center;
    background: #b6c9ee;

    span {
      padding: 10px 0 10px 20px;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: var(--color-primary);
    }
  }

  // quik messages
  .quick_message {
    display: flex;
    align-items: center;
    justify-content: space-around;
    // background: #eaf0fd;
    background: #fff;
    border-radius: 8px 8px 0px 0px;
    color: #002b49;
    cursor: pointer;
    padding: 5px 10px;
    margin-bottom: 20px;

    p {
      font-weight: bold;
      margin: 0 20px;
    }

    svg {
      margin-left: 20px;
    }

    div {
      display: flex;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .online_dot {
        height: 10px;
        width: 10px;
        background-color: #088b15;
        border-radius: 50%;
        display: inline-block;
        margin: 20px 0 0 -10px;
      }
    }
  }

  .list_container {
    height: calc(100vh - 210px);
    overflow-y: scroll;
    flex-grow: 1;
    // padding: 0 5px;
  }

  .msgBtn {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 20px;

    .msgAdd {
      /* Rectangle 89 */
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(147, 169, 214, 0.2);
      border-radius: 8px;
      margin-right: 10px;

      svg {
        color: #6b6b6b;
        font-size: 18px;
      }
    }

    p {
      color: #6b6b6b;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.5, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.user {
  display: flex;
  margin-bottom: 3px;
  cursor: pointer;
  padding: 8px 5px;

  img {
    margin-right: 10px;
    width: 45px;
    height: 45px;
    object-fit: fill;
  }

  img.recent {
    border-radius: 50%;
  }
  img {
    border-radius: 8px;
  }

  .details {
    .name_sec {
      display: flex;
      align-items: center;

      h3 {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: var(--color-primary);

        width: 127px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      small {
        font-size: 9px;
      }
    }

    p {
      margin-top: 3px;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      color: var(--color-primary);

      height: 26px;
      width: 178px;
      overflow: hidden;
      text-overflow: ellipsis;

      small {
        font-weight: bold;
      }
    }
  }
}
</style>
