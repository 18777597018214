<template>
  <c-modal :is-open="isShareOpen" :on-close="() => $emit('close')">
    <c-modal-content ref="content">
      <c-modal-header>{{ shareTitle }}</c-modal-header>
      <c-modal-close-button />
      <c-modal-body d="flex" flexWrap="wrap">
        <c-link
          color="vc-orange.400"
          fontSize="sm"
          @click="getShareLink"
          mx="2"
          py="3"
          px="3"
          my="3"
          display="flex"
          alignItems="center"
        >
          <i v-chakra color="black" class="far fah fa-lg fa-clipboard"></i>
          <c-text color="black" ml="2">Copy Link</c-text>
        </c-link>
        <ShareNetwork
          v-chakra
          d="flex"
          mx="2"
          py="3"
          px="3"
          my="3"
          v-for="network in networks"
          :network="network.network"
          :key="network.network"
          :style="{ backgroundColor: network.color }"
          :url="`https://google.com`"
          :title="postTitle"
          :description="postDescription"
          :quote="sharing.quote"
          :hashtags="sharing.hashtags"
          :twitterUser="sharing.twitterUser"
        >
          <i v-chakra color="white" :class="network.icon"></i>
          <c-text color="white" ml="2">{{ network.name }}</c-text>
        </ShareNetwork>
      </c-modal-body>
      <c-modal-footer>
        <c-button @click="$emit('close')">Cancel</c-button>
      </c-modal-footer>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import { mapState } from 'vuex';
import sjcl from 'sjcl';

export default {
  props: {
    url: String,
    isShareOpen: {
      type: Boolean,
      default: false,
    },
    shareTitle: {
      type: String,
      default: 'Share Post',
    },
    postTitle: {
      type: String,
      default: '',
    },
    postDescription: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sharing: {
        url: `https://news.vuejs.org/issues/`,
        title:
          'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
        description:
          'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: 'The hot reload is so fast it is near instant. - Evan You',
        hashtags: 'vibrantfounder,businessdevelopment,startup,vibrantfounder',
        twitterUser: 'vibrantcreator',
      },
      networks: [
        {
          network: 'email',
          name: 'Email',
          icon: 'far fah fa-lg fa-envelope',
          color: '#333333',
        },
        // {
        //   network: 'facebook',
        //   name: 'Facebook',
        //   icon: 'fab fah fa-lg fa-facebook-f',
        //   color: '#1877f2',
        // },

        // {
        //   network: 'linkedin',
        //   name: 'LinkedIn',
        //   icon: 'fab fah fa-lg fa-linkedin',
        //   color: '#007bb5',
        // },
        // {
        //   network: 'messenger',
        //   name: 'Messenger',
        //   icon: 'fab fah fa-lg fa-facebook-messenger',
        //   color: '#0084ff',
        // },

        // {
        //   network: 'pinterest',
        //   name: 'Pinterest',
        //   icon: 'fab fah fa-lg fa-pinterest',
        //   color: '#bd081c',
        // },

        // {
        //   network: 'skype',
        //   name: 'Skype',
        //   icon: 'fab fah fa-lg fa-skype',
        //   color: '#00aff0',
        // },
        // {
        //   network: 'sms',
        //   name: 'SMS',
        //   icon: 'far fah fa-lg fa-comment-dots',
        //   color: '#333333',
        // },

        // {
        //   network: 'telegram',
        //   name: 'Telegram',
        //   icon: 'fab fah fa-lg fa-telegram-plane',
        //   color: '#0088cc',
        // },

        // {
        //   network: 'twitter',
        //   name: 'Twitter',
        //   icon: 'fab fah fa-lg fa-twitter',
        //   color: '#1da1f2',
        // },

        // {
        //   network: 'whatsapp',
        //   name: 'Whatsapp',
        //   icon: 'fab fah fa-lg fa-whatsapp',
        //   color: '#25d366',
        // },
      ],
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    pageLink() {
      return `${location.origin}/app/`;
    },
  },
  methods: {
    async getShareLink() {
      let link = '';

      if (this.type === 'overview') {
        const numberString = this.activeCompany.id.toString();
        const encryptedId = sjcl.encrypt('E#2rjK7@Z9qy$L!', numberString);

        // Base64 encode the encrypted data
        const encodedId = btoa(encryptedId);

        link = `${this.pageLink}share?company_id=${encodedId}&page=overview&tab=${this.url}`;
      } else {
        link = `${this.pageLink}${this.url}`;
      }

      await navigator.clipboard.writeText(link);
      this.$toast({
        title: 'Link copied to clipboard',
        description: ``,
        status: 'success',
        position: 'top',
        duration: 3000,
      });
    },
  },
};
</script>
