<template>
  <header class="header" :class="[{ scrolled: isScrolled }]">
    <a href="https://vibrantfounder.com" class="header__logo">
      <img src="@/assets/img/logonew.png" alt="Vibrant Founder logo" />
    </a>
    <a
      class="header__btn"
      :class="[{ 'header__btn--opened': isMobileMenuOpen }]"
      @click="isMobileMenuOpen = !isMobileMenuOpen"
    >
      <span></span>
      <span></span>
      <span></span>
    </a>
    <ul
      class="header__list"
      :class="[{ 'header__list--opened': isMobileMenuOpen }]"
    >
      <li class="header__list__item">
        <a class="header__list__link" href="https://vibrantfounder.com/about-us"
          >About us</a
        >
      </li>
      <li class="header__list__item">
        <a class="header__list__link" href="https://vibrantfounder.com/features"
          >Features</a
        >
      </li>
      <li class="header__list__item">
        <a
          class="header__list__link"
          href="https://vibrantfounder.com/vibrant-future"
          >Vibrant future</a
        >
      </li>
      <li class="header__list__item">
        <router-link class="header__list__link" to="/auth/login"
          >Login</router-link
        >
      </li>
      <li class="header__list__item">
        <SiteButton :as="'nlink'" :to="'/auth/signup'" size="sm">
          Sign Up
        </SiteButton>
      </li>
    </ul>
  </header>
</template>

<script>
import SiteButton from './SiteButton.vue';

export default {
  components: {
    SiteButton,
  },
  data() {
    return {
      isScrolled: false,
      isMobileMenuOpen: false,
    };
  },
  mounted() {
    this.listenForScroll();
  },
  methods: {
    listenForScroll() {
      window.addEventListener('scroll', () => {
        this.isScrolled = window.scrollY > 20;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  @apply flex w-screen fixed bg-transparent h-20 px-6 items-center justify-between transition duration-300 z-30;
  &.scrolled {
    @apply bg-white shadow-lg;
  }
  &__logo {
    @apply flex items-center w-48;
  }
  &__list {
    @apply list-none bg-white overflow-hidden h-0 absolute left-0 right-0 transition-height top-20 px-6 shadow-lg duration-500;
    &__item {
      @apply w-max;
      &:not(&:last-child) {
        @apply mb-3;
      }
    }
    &__link {
      @apply font-medium text-lg;
      color: var(--primary-color);
    }
    &--opened {
      @apply h-56;
    }
  }
  &__btn {
    @apply flex flex-col w-10 cursor-pointer;
    span {
      @apply bg-primary my-1;
      height: 4px;
      border-radius: 10px;
      transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      &:nth-of-type(1) {
        @apply w-1/2;
      }
      &:nth-of-type(2) {
        @apply w-full;
      }
      &:nth-of-type(3) {
        @apply w-3/4;
      }
    }
    &--opened {
      span {
        &:nth-of-type(1) {
          transform-origin: bottom;
          transform: rotatez(45deg) translate(4px, 1px);
        }
        &:nth-of-type(2) {
          transform-origin: top;
          transform: rotatez(-45deg);
        }
        &:nth-of-type(3) {
          transform-origin: bottom;
          width: 50%;
          transform: translate(15px, -6px) rotatez(45deg);
        }
      }
    }
  }
  @screen lg {
    @apply px-12;
    &__logo {
      @apply w-64;
    }
    &__list {
      @apply items-center flex h-auto relative bg-transparent shadow-none top-auto left-auto;
      &__item {
        &:not(&:last-child) {
          @apply mr-5 mb-0;
        }
      }
      &__link {
        @apply font-medium text-sm;
        color: var(--primary-color);
      }
    }
    &__btn {
      @apply hidden;
    }
  }
}
</style>
