<template>
  <c-box
    px="6"
    pt="6"
    bg="#fff"
    borderRadius="8px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
    minHeight="200px"
  >
    <c-flex align="center" justify="space-between" mb="6">
      <c-flex align="center">
        <c-avatar
          cursor="pointer"
          @click="goToProfile"
          size="md"
          :name="
            post.companyId
              ? post.company.name
              : post.user.firstname + ' ' + post.user.lastname
          "
          :src="post.companyId ? post.company.logo : post.user.profilePhoto"
          borderWidth="0px"
          mr="2"
        />
        <c-text
          @click.native="goToProfile"
          cursor="pointer"
          fontWeight="600"
          fontSize="sm"
          mr="2"
        >
          <template v-if="post.companyId">
            {{ post.company.name }}
          </template>
          <template v-else>
            {{ post.user.firstname }} {{ post.user.lastname }}
          </template>
        </c-text>
        <c-text fontWeight="600" fontSize="sm" color="gray.400">
          {{ post.created_at | moment('from') }}
        </c-text>
      </c-flex>
      <c-flex align="center">
        <c-link @click="onSaveClick">
          <svg
            v-chakra="{
              w: '24px',
              h: '24px',
            }"
            v-if="post.post_saves.length"
          >
            <use href="@/assets/icons/icon-star-filled.svg#star"></use>
          </svg>
          <svg
            v-chakra="{
              fill: 'vc-orange.400',
              w: '24px',
              h: '24px',
            }"
            v-else
          >
            <use href="@/assets/icons/icon-star.svg#star"></use>
          </svg>
        </c-link>
        <c-popover placement="bottom" v-if="isMyPost">
          <c-popover-trigger>
            <c-link ml="4">
              <svg
                v-chakra="{
                  width: '10px',
                  height: '16px',
                  fill: '#ef5323',
                }"
              >
                <use href="@/assets/icons/vertical-dots.svg#dots"></use>
              </svg>
            </c-link>
          </c-popover-trigger>
          <c-popover-content w="max-content">
            <c-popover-body p="0">
              <c-list>
                <c-list-item>
                  <c-pseudo-box
                    fontSize="sm"
                    cursor="pointer"
                    @click="
                      $router.push({
                        name: 'ViewInsight',
                        params: { id: post.id },
                      })
                    "
                    p="3"
                  >
                    View Post
                  </c-pseudo-box>
                </c-list-item>

                <c-list-item>
                  <c-pseudo-box
                    fontSize="sm"
                    cursor="pointer"
                    @click="onDeleteClick"
                    p="3"
                  >
                    Delete Post
                  </c-pseudo-box>
                </c-list-item>
              </c-list>
            </c-popover-body>
          </c-popover-content>
        </c-popover>
      </c-flex>
    </c-flex>
    <!-- <c-flex align="center" mb="6">
      <template v-for="post_tag in post.post_tags">
        <c-flex
          color="blue.300"
          minWidth="120px"
          mr="5"
          mb="3"
          borderWidth="1px"
          borderRadius="4px"
          bg="blue.50"
          align="center"
          justify="center"
          h="30px"
          :key="post_tag.id"
          fontSize="xs"
          fontWeight="500"
        >
          {{ post_tag.tag.name }}
        </c-flex>
      </template>
    </c-flex> -->
    <c-text
      fontWeight="500"
      color="#000"
      mb="6"
      ref="postContainer"
      v-html="post.content"
      class="post__content"
    ></c-text>
    <c-flex mb="6" v-if="!previewModal">
      <template v-for="attachment in post.attachments">
        <c-flex
          w="40%"
          borderWidth="1px"
          borderRadius="6px"
          h="300px"
          mr="30px"
          cursor="pointer"
          overflow="hidden"
          v-if="attachment.type === 'image'"
          :key="attachment.id"
        >
          <img
            @click="$emit('selectPostAttachment', post)"
            v-chakra="{
              w: '100%',
              h: '100%',
              objectFit: 'cover',
              objectPosition: 'top center',
            }"
            :src="attachment.url"
            alt=""
            srcset=""
          />
        </c-flex>
        <c-flex
          w="40%"
          borderWidth="1px"
          borderRadius="6px"
          h="300px"
          mr="30px"
          cursor="pointer"
          overflow="hidden"
          v-if="attachment.type === 'video'"
          :key="attachment.id"
        >
          <video width="100%" height="100%" controls controlsList="nodownload">
            <source :src="attachment.url" type="video/mp4" />
            <source :src="attachment.url" type="video/ogg" />
            Your browser does not support the video format.
          </video>
        </c-flex>
      </template>
    </c-flex>
    <c-grid v-if="urls.length" template-columns="repeat(2, 1fr)" gap="8" mb="2">
      <c-link
        display="flex"
        borderWidth="1px"
        borderRadius="6px"
        h="120px"
        :href="url.url"
        v-for="url in urls"
        :key="url.id"
        is-external
        overflow="hidden"
      >
        <c-box
          backgroundSize="cover"
          backgroundPosition="top center"
          :style="{ backgroundImage: `url('${url.image}')` }"
          w="40%"
          h="100%"
        ></c-box>
        <c-box p="4" maxWidth="60%">
          <c-stack :spacing="2">
            <c-text fontSize="xs" fontWeight="600"> {{ url.title }} </c-text>
            <!-- <c-text
                fontSize="xs"
                fontWeight="500"
                color="gray.400"
                textOverflow="ellipsis"
              >
                {{ url.description || url['og:description'] }}
              </c-text> -->
            <!-- <c-link display="flex" fontSize="xs" align="center">
                <svg
                  v-chakra="{
                    fill: 'black',
                    w: '12px',
                    h: '12px',
                    mr: '2',
                  }"
                >
                  <use href="@/assets/icons/icon-link.svg#link"></use>
                </svg>
                {{ url.url }}
              </c-link> -->
          </c-stack>
        </c-box>
      </c-link>
    </c-grid>
    <c-flex py="2" mb="2">
      <c-pseudo-box
        :_hover="{
          textDecoration: 'none',
          cursor: 'pointer',
        }"
        display="flex"
        mr="4"
        alignItems="center"
        @click="isCommentBoxVisible = !isCommentBoxVisible"
      >
        <svg
          v-chakra="{
            fill: 'gray.400',
            w: '4',
            h: '4',
            mr: '2',
          }"
        >
          <use href="@/assets/icons/icon-chat.svg#chat"></use>
        </svg>
        <c-text color="gray.400" fontSize="sm">
          {{ post.post_comments.length }}
        </c-text>
      </c-pseudo-box>
      <c-pseudo-box
        :_hover="{
          textDecoration: 'none',
          cursor: 'pointer',
        }"
        display="flex"
        @click="togglePostLike"
        mr="4"
        alignItems="center"
      >
        <svg
          v-chakra="{
            fill: 'gray.400',
            w: '4',
            h: '4',
            mr: '2',
          }"
          v-if="!isLikedPost"
        >
          <use href="@/assets/icons/icon-heart.svg#heart"></use>
        </svg>
        <svg
          v-else
          v-chakra="{
            w: '4',
            h: '4',
            mr: '2',
          }"
        >
          <use href="@/assets/icons/icon-heart-red.svg#heart"></use>
        </svg>
        <c-text :color="isLikedPost ? 'red.300' : 'gray.400'" fontSize="sm">
          {{ postLikes.length }}</c-text
        >
      </c-pseudo-box>
      <c-pseudo-box
        :_hover="{
          textDecoration: 'none',
          cursor: 'pointer',
        }"
        display="flex"
        mr="4"
        alignItems="center"
        @click="onShareClick(post)"
      >
        <svg v-chakra fill="gray.400" w="4" h="4" ml="1">
          <use href="@/assets/icons/share-box-fill.svg#share"></use>
        </svg>
      </c-pseudo-box>
    </c-flex>
    <transition name="fade">
      <c-flex
        px="6"
        py="3"
        align="center"
        minHeight="80px"
        borderTopWidth="1px"
        mt="3"
        mx="-1.5em"
        v-show="isCommentBoxVisible || isEditMode"
      >
        <c-avatar
          :name="user.firstname + ' ' + user.lastname"
          :src="user.profilePhoto"
          mr="3"
        />
        <c-flex
          align="center"
          flexBasis="calc(100% - 65px)"
          flexGrow="0"
          borderWidth="1px"
          borderRadius="4px"
          maxWidth="calc(100% - 65px)"
        >
          <c-box
            flexBasis="calc(100% - 40px)"
            flexGrow="0"
            minHeight="40px"
            maxWidth="calc(100% - 40px)"
          >
            <quill-editor
              class="editor"
              ref="textEditor"
              v-model="newComment"
              @change="onChange"
              :options="editorOptions"
            />
          </c-box>
          <c-button
            px="0"
            border="none"
            :disabled="!newComment || !newComment.trim()"
            display="flex"
            justifyContent="center"
            variant="outline"
            @click="submitComment"
          >
            <c-spinner
              v-if="isSavingComment"
              color="blue.400"
              thickness="2px"
            ></c-spinner>
            <svg
              v-else
              v-chakra="{
                fill: 'gray.400',
                w: '16px',
                h: '16px',
              }"
            >
              <use href="@/assets/icons/icon-send-button.svg#send"></use>
            </svg>
          </c-button>
        </c-flex>
      </c-flex>
    </transition>
    <c-box px="6" py="5" v-if="postComments.length" mx="-1.5em" bg="gray.100">
      <c-stack :spacing="4">
        <template v-for="(comment, index) in postComments">
          <c-box :key="comment.id">
            <Comment
              :comment="comment"
              v-show="isAllCommentsShown || index == 0"
              @editComment="onEditComment(comment)"
              @deleteComment="onDeleteComment(comment)"
              v-chakra
            />
          </c-box>
        </template>
      </c-stack>
      <c-box mt="2" v-if="postComments.length > 1">
        <c-link
          @click="isAllCommentsShown = !isAllCommentsShown"
          fontWeight="600"
          fontSize="sm"
        >
          Show {{ isAllCommentsShown ? 'less' : 'more comments' }}
        </c-link>
      </c-box>
    </c-box>
    <c-alert-dialog
      :is-open="isDeleteDialogOpen"
      :least-destructive-ref="$refs.cancelRef"
      :on-close="closeDeleteDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold">
          Delete Comment
        </c-alert-dialog-header>
        <c-alert-dialog-body>
          Are you sure? You can't undo this action afterwards.
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeDeleteDialog">
            Cancel
          </c-button>
          <c-button variantColor="red" @click="deleteComment" ml="3">
            Delete
            <c-spinner
              ml="3"
              v-if="isDeletingComment"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
    <share-content
      :isShareOpen="isShareOpen"
      @close="onShareClick"
      :url="`insight/${postInAction.id}`"
      shareTitle="Share Post"
      :postTitle="'Post on VibrantFounder'"
      :postDescription="'Hey! I just shared a new post on VibrantFounder, check it out'"
    />
  </c-box>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';
import {
  addPostComment,
  addPostLike,
  deletePostLike,
  getMatchingTags,
  getMatchingUsers,
  updatePostComment,
} from '@/services/insight';

import Comment from './Comment.vue';
import { generateID } from '@/helpers/data';

import { Quill } from 'vue-quill-editor';

import 'quill-mention/dist/quill.mention.min.css';

import 'quill-mention';

import '@/helpers/editor/blots/mention';
import { getUrlMetadata } from '@/services/common';
import ShareContent from './../../components/ShareContent.vue';

export default {
  components: {
    Comment,
    ShareContent,
  },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    previewModal: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urls: [],
      postLikes: [],
      postComments: [],
      newComment: null,
      isImagePreviewModalOpen: false,
      isShareOpen: false,
      imageInPreview: '',
      editorOptions: {
        placeholder: `Comment`,
        modules: {
          toolbar: false,
          clipboard: {
            matchers: [[Node.TEXT_NODE, this.linkMatcher]],
          },
          mention: {
            maxChars: 31,
            isolateCharacter: true,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@', '#'],
            blotName: 'vc-mention',
            source: async (searchTerm, renderList, mentionChar) => {
              try {
                let matches = [];
                if (mentionChar === '@') {
                  matches = await this.getUserMatches(searchTerm);
                } else {
                  matches = await this.getTagMatches(searchTerm);
                }
                renderList(matches, searchTerm);
              } catch (e) {
                console.log({ e });
                renderList([], searchTerm);
              }
            },
          },
        },
      },
      isCommentBoxVisible: false,
      isAllCommentsShown: false,
      isSavingComment: false,
      isEditMode: false,
      editingComment: null,
      isDeleteDialogOpen: false,
      commentToDelete: null,
      isDeletingComment: false,
      postInAction: {},
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    isLikedPost() {
      return this.postLikes.some(
        (postLike) => postLike.user.id == this.user.id
      );
    },
    isMyPost() {
      return this.user.id === this.post.userId;
    },
    editor() {
      return this.$refs.textEditor.quill;
    },
    isStaging() {
      return (
        window.location.hostname.startsWith('develop') ||
        window.location.hostname.startsWith('localhost')
      );
    },
  },
  created() {
    this.getUrls();
    this.postLikes = cloneDeep(this.post.post_likes);
    this.postComments = cloneDeep(this.post.post_comments);
  },
  methods: {
    onShareClick(post = {}) {
      this.isShareOpen = !this.isShareOpen;
      this.postInAction = post;
    },
    urlify(text) {
      return text.replace(
        /^(?!href=").*(https?:\/\/[^\s]+)/g,
        '<a href="$1">$1</a>'
      );
      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
    goToProfile() {
      if (this.isStaging) {
        if (this.post.companyId) {
          this.$router.push({
            name: 'CompanyProfile',
            params: {
              company: this.post.companyId,
            },
          });
        } else {
          this.$router.push({
            name: 'Profile',
            params: {
              userId: this.post.userId,
            },
          });
        }
      }
    },
    getUrls() {
      const urls = [
        ...new Set(
          this.post.content.match(
            /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi
          )
        ),
      ];
      if (!urls.length) return;
      urls.forEach(async (url) => {
        try {
          const res = await getUrlMetadata(url);
          this.urls.push({
            id: generateID(4),
            ...res.data,
          });
        } catch (e) {
          // do something
        }
      });
    },
    handleCloseImagePreview() {
      this.isImagePreviewModalOpen = false;
      this.imageInPreview = '';
    },
    async togglePostLike() {
      if (this.isLikedPost) {
        const postLike = this.postLikes.find(
          (like) => like.userId === this.user.id
        );
        const postLikeIndex = this.postLikes.find(
          (like) => like.userId === this.user.id
        );
        try {
          this.postLikes.splice(postLikeIndex, 1);
          await deletePostLike(postLike.id);
        } catch (e) {
          this.postLikes.splice(postLikeIndex, 0, postLike);
          this.$toast({
            title: 'An error occurred.',
            description: `Could not unlike post, please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        const likeId = generateID(4);
        try {
          this.postLikes.push({
            id: likeId,
            user: { id: this.user.id },
          });
          const res = await addPostLike({
            postId: this.post.id,
            userId: this.user.id,
          });
          const postLikeIndex = this.postLikes.findIndex(
            (like) => like.id === likeId
          );
          this.postLikes[postLikeIndex] = cloneDeep(
            res.data.insert_post_like_one
          );
        } catch (e) {
          const postLikeIndex = this.postLikes.findIndex(
            (like) => like.id === likeId
          );
          this.postLikes.splice(postLikeIndex, 1);
          this.$toast({
            title: 'An error occurred.',
            description: `Could not like post, please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    handleCommentKeyUp(e) {
      if (e.key === 'Enter' || e.code === 'Enter') {
        this.addComment();
      }
    },
    submitComment() {
      if (this.isEditMode) {
        this.updateComment();
      } else {
        this.addComment();
      }
    },
    async addComment() {
      const {
        id: userId,
        email,
        profilePhoto,
        firstname,
        lastname,
      } = this.user;
      const data = {
        content: this.newComment,
        postId: this.post.id,
        userId,
        mentions: { data: this.getCommentMentions() },
      };
      const commentId = generateID(4);
      this.postComments.push({
        id: commentId,
        postId: this.post.id,
        userId,
        user: { id: userId, email, profilePhoto, firstname, lastname },
        content: this.newComment,
        replies: [],
        createdAt: new Date().toISOString(),
      });
      this.newComment = null;
      const commentIndex = this.postComments.findIndex(
        (comment) => comment.id == commentId
      );
      try {
        const res = await addPostComment(data);
        this.postComments[commentIndex] = cloneDeep(
          res.data.insert_post_comment_one
        );
      } catch (e) {
        this.postComments.splice(commentIndex, 1);
        this.$toast({
          title: 'An error occurred.',
          description: `Could not comment on post, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    setContentEmpty() {
      this.isContentEmpty = !this.editor.getText().trim();
    },
    linkMatcher(node, delta) {
      const regex = /https?:\/\/[^\s]+/g;
      if (typeof node.data !== 'string') return;
      const matches = node.data.match(regex);

      if (matches && matches.length > 0) {
        const ops = [];
        let str = node.data;
        matches.forEach(function (match) {
          const split = str.split(match);
          const beforeLink = split.shift();
          ops.push({ insert: beforeLink });
          ops.push({ insert: match, attributes: { link: match } });
          str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }

      return delta;
    },
    getTextBeforeCursor(cursorPosition) {
      const startPos = Math.max(
        0,
        cursorPosition - this.editorOptions.modules.mention.maxChars
      );
      const textBeforeCursorPos = this.editor.getText(
        startPos,
        cursorPosition - startPos
      );
      return textBeforeCursorPos;
    },
    openImagePreviewModal(images) {
      const preview = this.$imagePreview({
        initIndex: 0,
        images: [...images],
      });

      preview();
    },
    onChange() {
      const range = this.editor.getSelection();
      if (range == null) return;
      const cursorPosition = range.index;
      const textBeforeCursor = this.getTextBeforeCursor(cursorPosition);
      const indexOfHash = textBeforeCursor.lastIndexOf('#');
      const mentionCharPos =
        cursorPosition - (textBeforeCursor.length - indexOfHash);
      if (indexOfHash > -1) {
        this.renderHashTag({
          textBeforeCursor,
          indexOfHash,
          cursorPosition,
          mentionCharPos,
        });
      }
      this.setContentEmpty();
    },
    renderHashTag({
      textBeforeCursor,
      indexOfHash,
      cursorPosition,
      mentionCharPos,
    }) {
      if (!this.hasValidMentionCharIndex(indexOfHash, textBeforeCursor)) {
        return;
      }
      const textAfter = textBeforeCursor.substring(indexOfHash + 1);
      if (/\s$/.test(textBeforeCursor) && textAfter.trim()) {
        //   this.editor.getFormat()
        this.editor.deleteText(
          mentionCharPos,
          cursorPosition - mentionCharPos,
          Quill.sources.USER
        );
        this.editor.insertEmbed(
          mentionCharPos,
          this.editorOptions.modules.mention.blotName,
          { value: textAfter.trim(), denotationChar: '#' },
          Quill.sources.USER
        );
        this.editor.insertText(mentionCharPos + 1, ' ', Quill.sources.USER);
        this.editor.setSelection(mentionCharPos + 2, Quill.sources.USER);
      }
    },
    hasValidMentionCharIndex(mentionCharIndex, text) {
      if (mentionCharIndex > -1) {
        if (
          !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g))
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    async getUserMatches(searchTerm) {
      const res = await getMatchingUsers(`%${searchTerm}%`);
      const matches = res.data.user.map((user) => {
        return {
          id: user.id,
          value: `${user.firstname} ${user.lastname}`,
        };
      });
      return matches;
    },
    async getTagMatches(searchTerm) {
      const res = await getMatchingTags(`%${searchTerm}%`);
      const matches = res.data.tag.map((tag) => {
        return {
          id: tag.id,
          value: tag.name,
        };
      });
      return matches;
    },
    getCommentMentions() {
      const mentions = Array.from(
        this.$refs.textEditor.$el.querySelectorAll(
          '.mention[data-denotation-char="@"]'
        )
      ).map((el) => el.dataset);
      return mentions.map((mention) => {
        return {
          userId: mention.id,
        };
      });
    },
    onSaveClick() {
      this.$emit('togglePostSave', { ...this.post });
    },
    onDeleteClick() {
      this.$emit('deletePost');
    },
    onEditComment(comment) {
      this.editingComment = comment;
      this.isEditMode = true;
      this.newComment = comment.content;
      this.$nextTick(() => {
        this.editor.setSelection(this.editor.getLength(), 0);
      });
    },
    async updateComment() {
      if (this.newComment === this.editingComment.content) {
        this.isEditMode = false;
        this.newComment = null;
        this.editingComment = null;
        return;
      }
      this.isSavingComment = true;
      const commentIndex = this.postComments.findIndex(
        (comment) => comment.id === this.editingComment.id
      );
      try {
        const res = await updatePostComment({
          id: this.editingComment.id,
          set: { isEdited: true, content: this.newComment },
        });
        this.postComments[commentIndex].content =
          res.data.update_post_comment_by_pk.content;
        this.postComments[commentIndex].isEdited = true;
        this.isSavingComment = false;
        this.isEditMode = false;
        this.newComment = null;
        this.editingComment = null;
      } catch (e) {
        console.log({ e });
        this.isSavingComment = false;
      }
    },
    onDeleteComment(comment) {
      this.isDeleteDialogOpen = true;
      this.commentToDelete = { ...comment };
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.commentToDelete = null;
    },
    async deleteComment() {
      this.isDeletingComment = true;
      try {
        await updatePostComment({
          id: this.commentToDelete.id,
          set: { isDeleted: true },
        });
        const commentIndex = this.postComments.findIndex(
          (comment) => comment.id === this.commentToDelete.id
        );
        this.postComments.splice(commentIndex, 1);
        this.closeDeleteDialog();
        this.$toast({
          title: 'Success!!!',
          description: `Comment has been deleted`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
        this.isDeletingComment = false;
      } catch (e) {
        this.isDeletingComment = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Could not delete post, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.mention {
  background-color: transparent;
  @apply text-blue-500 font-semibold;
}
.post {
  &__content {
    a {
      @apply text-blue-500 font-semibold;
    }
  }
}
</style>

<style lang="scss" scoped>
.editor {
  @apply max-w-full;
  ::v-deep {
    .ql-container {
      border-width: 0px;
      font-size: inherit;
      font-family: inherit;
    }
    .mention {
      @apply font-bold bg-transparent;
    }
    .ql-mention-list {
      @apply overflow-y-scroll;
      max-height: 300px;
    }
  }
}
</style>
