<template>
  <div style="width: 100" class="flex flex-col min-h-screen">
    <!-- SideBar -->
    <div
      style="background-color: #002b49ff"
      :class="[
        'w-24 fixed left-0 top-0 bottom-0 text-white',
        isSidebarOpen ? 'block' : 'hidden',
        'md:block',
      ]"
    >
      <div>
        <c-box class="text-bold text-secondary mb-7">
         <c-image :src="require('@/assets/img/v_logo.jpeg')" w="full"/>
        </c-box>
        <ul class="space-y-2">
          <li>
            <router-link active-class="active" to="/affiliate/dashboard">
              <svg>
                <use href="@/assets/icons/dashboard-line.svg#dashboard" />
              </svg>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li>
            <router-link exact-active-class="active" to="/affiliate/coupons">
              <c-icon class="ico" name="receipt" />
              <span>Coupons</span>
            </router-link>
          </li>
          <li>
            <router-link active-class="active" to="/affiliate/referrals">
              <c-icon class="ico" name="building" />
              <span>Referrals</span>
            </router-link>
          </li>
          <li>
            <router-link exact-active-class="active" to="/affiliate/earnings">
              <c-icon class="ico" name="hand-holding-usd" />
              <span>Earnings($)</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div style="position: absolute; bottom: 20px; justify-content: center">
        <c-icon-button
            @click="$router.push('/app/growth-framework/')"
            variant-color="orange"
            aria-label="logout"
            icon="sign-out-alt"
        />
      </div>
    </div>
    <!-- Main Content -->
    <div
      class="flex-1 px-4 ml-24"
    >
      <!-- Header -->
      <div class="flex justify-between items-center">
        <c-box class="header__title">
          Affiliate Dashboard
          <div>
            <c-text color="primary" fontWeight="700" fontSize="md">
              Hi {{ user.firstname }}, welcome back! 👋
            </c-text>
          </div>
        </c-box>
<!--        <c-button @click="toggleSidebar" class="block hidden justify-self-end">-->
<!--          <c-icon name="bars" />-->
<!--        </c-button>-->
      </div>
      <div style="width: 100%">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {mapGetters} from "vuex";

export default {
  name: "Index",
  data() {
    return {
      isMenuOpen: false,
      isTeamDrawerOpen: false,
      isSidebarOpen: true,
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    })
  },

  methods: {
    router() {
      return router
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
}
</script>

<style scoped lang="scss">

.icon, a {
  background-color: $color-primary;
  color: #fff;
  @apply h-full flex flex-col items-center justify-center text-center py-2 px-2 font-semibold text-xs;
  svg {
    @apply mb-2;
    width: 24px;
    height: 24px;
    fill: #fff;
  }
  span {
    color: #fff;
  }
  &.active,
  &:hover {
    background-color: #fff;
    color: $color-primary;
    svg {
      fill: $color-primary;
    }
    span {
      color: $color-primary;
    }
  }
}

.header {
  height: 80px;
  position: fixed;
  display: flex;
  align-content: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-grey-light;

  &__title {
    font-size: 1.8em;
    font-weight: bold;
    color: $color-orange;
  }

}
.content{
  @apply w-full max-w-full h-full max-h-full overflow-y-scroll;
  background-color: #f9fbfd;
}
</style>