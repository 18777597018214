import { render, staticRenderFns } from "./Overview.vue?vue&type=template&id=275845dc"
import script from "./Overview.vue?vue&type=script&lang=js"
export * from "./Overview.vue?vue&type=script&lang=js"
import style0 from "./Overview.vue?vue&type=style&index=0&id=275845dc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CLink: require('@chakra-ui/vue').CLink, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CGrid: require('@chakra-ui/vue').CGrid, CStack: require('@chakra-ui/vue').CStack, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
