import { render, staticRenderFns } from "./EarningsSummaryCard.vue?vue&type=template&id=2f2a0d5c&scoped=true"
import script from "./EarningsSummaryCard.vue?vue&type=script&lang=js"
export * from "./EarningsSummaryCard.vue?vue&type=script&lang=js"
import style0 from "./EarningsSummaryCard.vue?vue&type=style&index=0&id=2f2a0d5c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f2a0d5c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CSelect: require('@chakra-ui/vue').CSelect, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CSpinner: require('@chakra-ui/vue').CSpinner})
